import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router';
import {Button, Form, Input, TextArea, Toast} from 'antd-mobile';
import {reqAddCustomer, reqGetCustomers, reqUpdateCustomer} from '../../api/customer';

// 供货商编辑
const ManufacturerEdit = (props) => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const {mode} = props;

    const {id} = useParams();

    useEffect(() => {
        if (id && mode === 'edit') {
            fillForm();
        }
    }, []);

    const fillForm = async () => {
        const res = await reqGetCustomers({
            customerIds: id,
            manufacturer: 1
        });
        if (res.code !== 200 || res.data.length !== 1) return;
        form.setFieldsValue(res.data[0]);
    }

    const handleOk = async () => {
        const data = form.getFieldsValue();
        data['manufacturer'] = 1;
        let res;
        if (mode === 'add') {
            res = await reqAddCustomer(data);
        } else {
            data['id'] = id;
            res = await reqUpdateCustomer(data);
        }
        if (res.code !== 200) return;
        Toast.show({
            icon: 'success',
            content: res.msg
        });
        navigate(-1);
    }

    return (
        <div>
            <Form form={form} footer={
                <Button onClick={handleOk} block color='primary' size='large'>
                    提交
                </Button>
            } layout='horizontal' >
                <Form.Item name={'customerName'} label={'供应商名称'}>
                    <Input placeholder={'请输入供应商名称'} />
                </Form.Item>
                <Form.Item name={'phone'} label={'联系方式'}>
                    <TextArea placeholder={'请输入联系方式'} />
                </Form.Item>
                <Form.Item name={'address'} label={'地址信息'}>
                    <TextArea placeholder={'请输入地址信息'} />
                </Form.Item>
                <Form.Item name={'remark'} label={'备注信息'}>
                    <TextArea placeholder={'请输入备注信息'} />
                </Form.Item>
            </Form>
        </div>
    );
};

export default ManufacturerEdit;
