import axios from 'axios';
import {Toast} from 'antd-mobile';
import {reqRefresh} from './login';

//export const baseURL = 'http://localhost:5041/api';
//export const baseURL = 'http://192.168.100.148:5041/api';
//export const baseURL = 'http://192.168.3.94:5041/api';
export const baseURL = 'http://api.jwshop.site/api';
//export const baseURL = 'http://120.46.57.123:5100/api';

const instance = axios.create({
   baseURL: baseURL,
});

// 请求拦截器
instance.interceptors.request.use((config) => {
    config.headers.Authorization = localStorage.getItem('jwt');
    return config;
}, (error) => {
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(
    function (response) {
        try {
            if (response.data.code && response.data.code !== 200) {
                Toast.show({
                    icon: 'fail',
                    content: response.data.msg
                });
            }
        } catch {

        }

        return response.data;
    },
    async function (err) {
        if (err.response) {
            switch (err.response.status) {
                case 400:
                    Toast.show({
                        icon: 'fail',
                        content: '请检查输入项',
                    });
                    break;
                case 401:
                    const res = await reqRefresh();
                    // 续签
                    if (res.code !== 200) {
                        Toast.show({
                            content: res.msg,
                            icon: 'fail'
                        });
                        localStorage.removeItem('jwt');
                        window.location.reload();
                        return;
                    }
                    // 设置Token
                    localStorage.setItem('jwt', res.data.access_token);
                    err.config.headers.Authorization = res.data.access_token
                    return instance(err.config);
                default:
                    break;
            }
        }
        // 对响应错误做点什么
        return Promise.reject(err);
    }
);

export default instance;
