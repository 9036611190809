import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {Button, ErrorBlock, Form, Image, ImageViewer, List, NavBar, Space, Tag, Toast} from 'antd-mobile';
import {reqvalidateCustomerGuid} from '../../api/customer';
import {get} from 'axios';
import {reqGetOrderGoodPhotos, reqGetOrderGoods, reqGetOrders} from '../../api/order';
import dayjs from 'dayjs';
import Table from 'rc-table';

const OrderList = () => {

    const {id} = useParams();
    const [customerInfo, setCustomerInfo] = useState({});

    const [books, setBooks] = useState([]);

    useEffect(() => {
        document.title = '嘉伟商行预订';
        validateCustomer();
    }, [id]);

    const validateCustomer = async () => {
        const res = await reqvalidateCustomerGuid(id);
        if (res.code !== 200) {
            Toast.show({
                content: '无法访问该页面 请联系嘉伟商行工作人员'
            });
            setCustomerInfo(null);
            return;
        };
        await getBookList();
        setCustomerInfo(res.data);
    }

    const getBookList = async (query = {}) => {
        const res = await reqGetOrders(query, id);
        if (res.code !== 200) return;
        setBooks(res.data);
    }

    const navigator = useNavigate();

    const generateStatus = (text) => {
        switch (text) {
            case 0:
                return <Tag style={{fontSize: '18px'}}>已下单</Tag>
        }
    }

    return (
        customerInfo ?
        <div>
            <NavBar style={{position:'fixed', padding: 0, width: '100%', backgroundColor: '#f0f0f0', zIndex: 100, top: 0}}
                    onBack={() => {
                        navigator('/order/' + id);
                    }}><div style={{fontWeight: 'bold'}}>
                订单列表
            </div></NavBar>
            <div style={{paddingTop: '50px', paddingBottom: '50px'}}>
                <Form>
                    <Form.Item label={'下单时间'}>

                    </Form.Item>
                </Form>
                <Table columns={[
                    {
                        title: ' ',
                        width: 25,
                        render: (text, record, index) => <Space style={{whiteSpace: 'nowrap', height: '40px'}} align={'center'}>
                            {index + 1}
                        </Space>,
                    },
                    {
                        title: '下单时间',
                        dataIndex: 'createTime',
                        width: 180,
                        render: (text) => <Space style={{whiteSpace: 'nowrap'}} align={'center'}>
                            {dayjs(text).format('YYYY年MM月DD日 HH:mm')}
                        </Space>
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        render: (text) => <Space align={'center'} justify={'center'}>
                            {generateStatus(text)}
                        </Space>
                    },
                    {
                        title: ' ',
                        width: 70,
                        render: (_, record) => <Button onClick={() => {
                            navigator(`/orderdetail/${id}/${record.id}`)
                        }} block color={'primary'} size={'mini'}>
                            查看
                        </Button>
                    }
                ]} data={books} emptyText={'暂无预订单哦~'} />
            </div>
        </div>:
        <ErrorBlock fullPage={true} status='default' />
    );
};

export default OrderList;
