export const returnGoodPrice = (good, standard) => {
    switch (standard)
    {
        case '斤':
            return good.kgPrice ??= 0;
        case '件':
            return good.wholePrice ??= 0;
        default:
        case '包':
            return good.unitPrice ??= 0;
    }
    return 0;
}
