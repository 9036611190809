import React, {useEffect, useState} from 'react';
import {useZxing} from 'react-zxing';
import useSound from 'use-sound';
import mp3 from '../../sound/scan.mp3';
import {Modal, Toast} from 'antd-mobile';

// 扫描条形码
const Scan = ({setCode}) => {
    const [playOn] = useSound(
        mp3,
        { volume: 0.8 }
    );
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [cameraDevices, setCameraDevices] = useState([]);

    useEffect(() => {
        // 获取设备列表
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                // 筛选相机设备并排除前置摄像头
                const cameras = devices.filter(device => device.kind === 'videoinput' && (device.focusMode === 'environment' || device.label.includes('后置')));
                setCameraDevices(cameras);
                if (cameras.length > 0) {
                    // 默认选择第一个摄像头
                    setSelectedCamera(cameras[0].deviceId);
                }
            })
            .catch(error => {
                Toast.show({
                    content: '摄像头打开错误'
                });
            });
    }, []);

    const { ref } = useZxing({
        deviceId: selectedCamera, // 设置所选摄像头的设备ID
        onDecodeResult(result) {
            if (result.getText() !== '') {
                playOn();
                setCode(result.getText());
            }
        },
        constraints: {
            video: { facingMode: 'environment' },
            audio: false,
            focusMode: "continuous",
        },
        timeBetweenDecodingAttempts: 200
    });
    const handleCameraChange = (event) => {
        setSelectedCamera(event.target.value);
    };
    const handleVideoClick = () => {

    };

    return (
        <div>
            <select value={selectedCamera} onChange={handleCameraChange}>
                {cameraDevices.map(camera => (
                    <option key={camera.deviceId} value={camera.deviceId}>
                        {camera.label || `Camera ${cameraDevices.indexOf(camera) + 1}`}
                    </option>
                ))}
            </select>
            <video
                onClick={handleVideoClick}
                style={{maxHeight: '75vh'}}
                ref={ref} />

        </div>
    );
};

export default Scan;
