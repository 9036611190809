import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {reqvalidateCustomerGuid} from '../../api/customer';
import {
    Badge,
    Button, Divider,
    ErrorBlock, Form,
    Grid, Image, ImageViewer, Input,
    List,
    Popup,
    SearchBar, Selector,
    Space, Stepper,
    TabBar,
    Toast
} from 'antd-mobile';
import {FileOutline, ShopbagOutline, CloseOutline} from 'antd-mobile-icons';
import {reqGetOrderGoodPhotos, reqGetOrderGoods} from '../../api/order';
import goodList from '../good/goodList';
import {baseURL} from '../../api/api';

const Order = (props) => {
    const [standForm] = Form.useForm();
    const [customerInfo, setCustomerInfo] = useState({});

    const {id} = useParams();

    const navigator = useNavigate();

    const [scrollDirection, setScrollDirection] = useState('down'); // 跟踪滚动方向，默认向下
    const [prevScrollPos, setPrevScrollPos] = useState(0); // 上一个滚动位置
    const [isHeaderVisible, setIsHeaderVisible] = useState(true); // 控制头部显示隐藏

    const [goodDatas, setGoodDatas] = useState([]);

    const [shopVisible, setShopVisible] = useState(false);

    const [standVisible, setStandVisible] = useState(false);

    //{
    //   1: {goodName: '', standard: {'unit': 0, 'whole': 0, 'kg': 0}}
    // }
    const [shopList, setShopList] = useState(new Map());

    useEffect(() => {
        document.title = '嘉伟商行预订';
        validateCustomer();
        if (localStorage.getItem('shopList')) {
            setShopList(new Map(JSON.parse(localStorage.getItem('shopList'))));
        }
    }, [id]);

    useEffect(() => {
        if (shopList.size > 0) {
            localStorage.setItem('shopList', JSON.stringify(Array.from(shopList.entries())));
        }
    }, [shopList]);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset; // 获取当前滚动位置
            if (currentScrollPos > prevScrollPos) {
                // 向下滚动
                setScrollDirection('down');
                setIsHeaderVisible(false); // 向下滚动时隐藏头部
            } else {
                // 向上滚动
                setScrollDirection('up');
                setIsHeaderVisible(true); // 向上滚动时显示头部
            }
            setPrevScrollPos(currentScrollPos); // 更新上一个滚动位置
        };

        window.addEventListener('scroll', handleScroll); // 监听滚动事件

        return () => {
            window.removeEventListener('scroll', handleScroll); // 组件卸载时移除事件监听
        };
    }, [prevScrollPos]);

    const validateCustomer = async () => {
        const res = await reqvalidateCustomerGuid(id);
        if (res.code !== 200) {
            Toast.show({
                content: '无法访问该页面 请联系嘉伟商行工作人员'
            });
            setCustomerInfo(null);
            return;
        };
        await getGoods();
        setCustomerInfo(res.data);
    }

    // 获取数据
    const getGoods = async (query = {}) => {
        const res = await reqGetOrderGoods(query, id);
        if (res.code !== 200) return;
        setGoodDatas(res.data);
    }

    // 加购物车
    const handleAddShop = () => {
        if (standForm.getFieldValue('number') === 0)
        {
            Toast.show({
                content: '数量不能为0'
            });
            return;
        }
        if (standForm.getFieldValue('standard').length === 0)
        {
            Toast.show({
                content: '请选择规格'
            })
        }

        const data = standForm.getFieldValue();
        const map = new Map(shopList);

        if (map.has(data.id)) {
            const obj = map.get(data.id);
            obj['goodName'] = data.goodName;
            obj['mainPhoto'] = data.mainPhoto;
            if (obj.hasOwnProperty(data.standard[0])) {
                obj[data.standard[0]] += data.number;
            } else {
                obj[data.standard[0]] = data.number;
            }
            map.set(data.id, obj);
        } else {
            const obj = {};
            obj['goodName'] = data.goodName;
            obj['mainPhoto'] = data.mainPhoto;
            obj[data.standard[0]] = data.number;
            map.set(data.id, obj);
        }
        setShopList(map);
        Toast.show({
            content: '加入成功'
        });
        standForm.resetFields();
        setStandVisible(false);
    }

    const returnChineseStandard = (key) => {
        const obj = {unit: '包', whole: '件', kg: '斤'};
        return obj[key];
    }

    // 生成加入购物车
    const generateExtra = (good) => {
        if (shopList.has(good.id)) {
            const obj = shopList.get(good.id);
            const keys = Object.keys(obj).filter(item => item !== 'goodName' && item !== 'mainPhoto');
            if (keys.length === 1 && shopList.get(good.id)[keys[0]] !== 0) {
                return <Space style={{paddingTop: '70px'}} align={'center'}>
                    <Stepper
                        min={0}
                        onChange={(value) => {
                            const map = new Map(shopList);
                            const o = map.get(good.id);
                            o[keys[0]] = value;
                            if (value === 0) {
                                map.delete(good.id);
                                setShopList(map);
                                return;
                            }
                            map.set(good.id, o);
                            setShopList(map);
                        }}
                        value={shopList.get(good.id)[keys[0]]} />
                    <div>
                        {returnChineseStandard(keys[0])}
                    </div>
                </Space>;
            }
        }
        return <Space style={{paddingTop: '70px'}} direction={'vertical'}>
            <Button onClick={() => {
                setStandVisible(true);
                standForm.resetFields();
                standForm.setFieldValue('id', good.id);
                standForm.setFieldValue('goodName', good.goodName);
                standForm.setFieldValue('mainPhoto', good.mainPhoto);
                standForm.setFieldValue('standard', ['unit']);
                standForm.setFieldValue('number', 1);
            }} color={'primary'} size={'small'}>
                加入购物车
            </Button>
        </Space>;
    }

    // 获取数量
    const getShopListSize = () => {
        if (shopList.size === 0) return 0;
        let sum = 0;
        shopList.forEach((value, key) => {
            if (value.whole) {
                sum += value.whole;
                return;
            }
            if (value.kg) {
                sum += 1;
                return;
            }
            if (value.unit) {
                sum += value.unit;
            }
        });
        return sum;
    }

    let timeout2;
    const tabs = [
        {
            key: '/home',
            title: '订单',
            icon: <FileOutline onClick={() => {
                navigator('/orderlist/' + id);
            }} />,
        },
        {
            key: '/todo',
            title: '购物车',
            icon: <Badge style={{'--top': '5px'}} content={shopList.size === 0 ? '' : getShopListSize()}>
                <ShopbagOutline onClick={() => {
                    setShopVisible(!shopVisible);
                }} />
            </Badge>,
        }
    ];

    const generateShopList = () => {
        if (shopList.size === 0) {
            return <ErrorBlock
                style={{padding: '50px'}}
                status={'empty'}
                title={'购物车还是空的哦~'}
                description={''}
            />;
        }
        const list = [];
          shopList.forEach((value, key) => {
            list.push(<List.Item
                key={value.goodName}
                prefix={
                    <Image
                        src={value.mainPhoto ? baseURL + '/good/photo/path/' + value.mainPhoto : null}
                        style={{ borderRadius: 4 }}
                        fit='cover'
                        onClick={async () => {
                            const res = await reqGetOrderGoodPhotos(key, id);
                            if (value.mainPhoto && res.code === 200) {
                                ImageViewer.Multi.show({ images: res.data.map(item => {
                                        return baseURL + '/good/photo/path/' + item;
                                    }) })
                            }
                        }}
                        lazy={true}
                        width={100}
                        height={100}
                    />
                }
                extra={generateExtra({
                    id: key,
                    goodName: value.goodName
                })}
            >
                <div style={{height: '100px', fontWeight: 'bold'}}>
                    {value.goodName}
                </div>
            </List.Item>);
        });
        return list;
    }

    // 添加订单
    const handleAddOrder = async () => {
        if (shopList.size === 0) {
            Toast.show({ content: '请选择商品' });
            return;
        }
        navigator('/addorder/' + id);
    }

    const footer = <div style={{position:'fixed', width: '100%',bottom: '0',backgroundColor: 'white', zIndex: '99'}}>
        <Grid style={{alignItems: 'center'}} columns={10}>
            <Grid.Item span={3}>
                <TabBar activeKey={null}>
                    {tabs.map(item => (
                        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                    ))}
                </TabBar>
            </Grid.Item>
            <Grid.Item span={7}>
                <Button size={'large'} onClick={handleAddOrder} color={'primary'} block>
                    下单
                </Button>
            </Grid.Item>
        </Grid>
    </div>;

    return (
        customerInfo ?
        <div>
            {
                <div style={{position:'fixed', padding: 0, width: '100%', backgroundColor: '#f0f0f0', zIndex: 100, top: 0}}>
                    <SearchBar
                        style={{padding: '5px 5px'}}
                        onClear={async () => {
                            await getGoods();
                        }}
                        onChange={(value) => {
                            let query = {};
                            if (value) {
                                query = {goodName: value};
                            }
                            clearTimeout(timeout2);
                            // 设置一个延迟，比如500毫秒
                            timeout2 = setTimeout(async () => {
                                await getGoods(query);
                            }, 200);
                        }}
                        placeholder='搜索商品名称'
                        showCancelButton={() => true}  />
                </div>
            }
            <div style={{paddingTop: '35px', paddingBottom: '50px'}}>
                <List header='嘉伟商行预定商品列表'>
                    { goodDatas.length === 0 ? <ErrorBlock status={'empty'} /> : goodDatas.map(good => (
                        <List.Item
                            key={good.goodName}
                            prefix={
                                <Image
                                    src={good.mainPhoto ? baseURL + '/good/photo/path/' + good.mainPhoto : null}
                                    style={{ borderRadius: 4 }}
                                    fit='cover'
                                    onClick={async () => {
                                        const res = await reqGetOrderGoodPhotos(good.id, id);
                                        if (good.mainPhoto && res.code === 200) {

                                            ImageViewer.Multi.show({ images: res.data.map(item => {
                                                    return baseURL + '/good/photo/path/' + item;
                                                }) })
                                        }
                                    }}
                                    lazy={true}
                                    width={100}
                                    height={100}
                                />
                            }
                            extra={generateExtra(good)}
                        >
                            <div style={{height: '100px', fontWeight: 'bold'}}>
                                {good.goodName}
                            </div>
                        </List.Item>
                    )) }
                </List>
            </div>
            <Popup onMaskClick={() => setShopVisible(false)} visible={shopVisible}>
                <Space style={{padding: '10px', fontSize: '21px'}} block justify={'between'}>
                    <div>
                        已选择{getShopListSize()}个商品
                    </div>
                    <div>
                        <CloseOutline onClick={() => setShopVisible(false)} />
                    </div>
                </Space>
                <div style={{height: '50vh', overflowY: 'auto', paddingBottom: '50px'}}>
                    <List>
                        {
                            generateShopList()
                        }
                    </List>
                </div>
                {footer}
            </Popup>
            <Popup onMaskClick={() => setStandVisible(false)} visible={standVisible}>
                <Space style={{padding: '10px', fontSize: '21px'}} block justify={'between'}>
                    <div>
                        请选择规格
                    </div>
                    <div>
                        <CloseOutline onClick={() => setStandVisible(false)} />
                    </div>
                </Space>
                <Form form={standForm} footer={
                    <Button onClick={handleAddShop} block color='primary' size='large'>
                        加入购物车
                    </Button>
                } >
                    <Form.Item name={'mainPhoto'} hidden>
                    </Form.Item>
                    <Form.Item name={'id'} hidden>
                    </Form.Item>
                    <Form.Item name={'goodName'} hidden>
                    </Form.Item>
                    <Form.Item name={'standard'} label={'规格'}>
                        <Selector
                            columns={3}
                            options={[
                                {
                                    label: '包',
                                    value: 'unit',
                                },
                                {
                                    label: '件',
                                    value: 'whole',
                                },
                                {
                                    label: '斤',
                                    value: 'kg',
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item name={'number'} label={'数量'}>
                        <Stepper min={0}
                                 step={1}/>
                    </Form.Item>
                </Form>
            </Popup>
            {footer}
        </div> :
            <ErrorBlock fullPage={true} status='default' />
    );
};

export default Order;
