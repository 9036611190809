import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Home from './pages/home/home';
import {useEffect, useState} from 'react';
import Login from './pages/login/login';
import Order from './pages/order/order';
import OrderList from './pages/order/orderList';
import AddOrder from './pages/order/addOrder';
import OrderDetail from './pages/order/orderDetail';
function App() {

  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem('jwt'));

  useEffect(() => {

    document.title = '嘉伟商行后台管理系统';
  }, []);

  const router = createBrowserRouter([
    {
        path: '/order/:id',
        element: <Order />
    },
    {
      path: '/orderdetail/:id/:bookid',
      element: <OrderDetail/>
    },
    {
        path: '/addorder/:id',
        element: <AddOrder />
    },
    {
        path: '/orderlist/:id',
        element: <OrderList />
    },
    {
      path: '/login',
      element: loggedIn ? <Home /> : <Login setLoggedIn={setLoggedIn} />
    },
    {
      path: "/*",
      element: loggedIn ? <Home /> : <Login setLoggedIn={setLoggedIn} />,
    },

  ]);

  return (
        <RouterProvider router={router} />
  );
}

export default App;
