import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {
    ActionSheet,
    Button,
    CheckList,
    Dialog,
    ErrorBlock,
    Form,
    Input,
    Popup,
    SearchBar,
    Space,
    Toast
} from 'antd-mobile';
import {
    reqAddCustomerRelation,
    reqDeleteCustomers,
    reqGetCustomerGuid,
    reqGetCustomers,
    reqGetCustomersBill,
    reqUpdateCustomerGuid
} from '../../api/customer';
import Table from 'rc-table';
import {FormatMoney} from '../../utils/formatMoney';
import {FillinOutline} from 'antd-mobile-icons';
import styles from '../deliverynotes/addDeliveryNote.css';

// 供应商列表
const Manufacturer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [form1] = Form.useForm();
    const [customers, setCustomers] = useState([]);
    const [editSelect, setEditSelect] = useState(null);
    const [query, setQuery] = useState({
        // startTime: dayjs().startOf('month'),
        // endTime: dayjs().endOf('month'),
        customerIds: null
    });
    const [relationCheckList, setRelationCheckList] = useState([]);
    const [relationCustomer, setRelationCustomer] = useState([]);
    const [relationVisible, setRelationVisible] = useState(null);
    const getCustomers = async (query = {}) => {
        query['manufacturer'] = 1;
        const res = await reqGetCustomersBill(query);
        if (res.code !== 200) return;
        let key = 0;
        res.data.forEach(item => {
            item['key'] = key++;
        });

        setCustomers(res.data);
    }

    useEffect(() => {
        getCustomers();
    }, []);
    let timeout2;

    return (
        <div>
            <SearchBar style={{padding: '5px 5px'}}
                       onClear={async () => {
                           await getCustomers();
                       }}
                       onChange={(value) => {
                           let query = {};
                           if (value) {
                               query = {customerName: value};
                           }
                           clearTimeout(timeout2);
                           // 设置一个延迟，比如500毫秒
                           timeout2 = setTimeout(async () => {
                               await getCustomers(query);
                           }, 200);
                       }}
                       placeholder='供应商名称'
                       showCancelButton={() => false} />
            <Table
                columns={[
                    {
                        title: customers === 0 ? `供应商` : `供应商(${customers.length})`,
                        dataIndex: 'customerName',
                    },
                    {
                        title: '欠款',
                        dataIndex: 'arrears',
                        width: 90,
                        render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                            {FormatMoney(value)}
                        </div>
                    },
                    // {
                    //     title: '关联供应商',
                    //     dataIndex: 'childrenNumber',
                    // },
                    // {
                    //     title: '单数',
                    //     width: 50,
                    //     dataIndex: 'deliveryNumber',
                    //     render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                    //         {value}
                    //     </div>
                    // },
                    // {
                    //     title: '未结',
                    //     width: 50,
                    //     dataIndex: 'unConfirmNumber',
                    //     render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                    //         {value}
                    //     </div>
                    // },
                    {
                        title: ' ',
                        width: 20,
                        render: (_, record) => <FillinOutline color={'#237804'} onClick={() => {
                            setEditSelect(record);
                        }} fontSize={'20px'}/>
                    }
                ]}
                emptyText={'暂无数据'}
                data={customers}
            />

            <ActionSheet
                cancelText='取消'
                visible={editSelect}
                actions={[
                    {
                        bold: true,
                        text: '供应商对账单',
                        onClick: () => {
                            const data = editSelect;
                            setEditSelect(null);
                            navigate('/manufacturerstatement/' + data.id);
                        },
                        key: 1
                    },
                    {
                        text: '编辑供应商信息',
                        onClick: () => {
                            const data = editSelect;
                            setEditSelect(null);
                            navigate('/editmanufacturer/' + data.id);
                        },
                        key: 2
                    },
                    {
                        text: '删除供应商信息',
                        danger: true,
                        bold: true,
                        onClick: () => {
                            const data = editSelect;
                            setEditSelect(null);
                            Dialog.show({
                                content: '是否删除该供应商信息',
                                closeOnAction: true,
                                actions: [
                                    [
                                        {
                                            key: 'cancel',
                                            text: '取消',
                                        },
                                        {
                                            key: 'delete',
                                            text: '删除',
                                            bold: true,
                                            danger: true,
                                            onClick: async () => {
                                                const res = await reqDeleteCustomers(data.id);
                                                if (res.code !== 200) return;
                                                Toast.show({
                                                    content: '删除成功',
                                                });
                                                await getCustomers();
                                            }
                                        },
                                    ],
                                ],
                            });
                        }
                    }
                ]}
                onClose={() => setEditSelect(null)}
            />
        </div>
    );
};

export default Manufacturer;
