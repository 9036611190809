// SignalRService.js
import * as signalR from "@microsoft/signalr";
import {baseURL} from '../api/api';

class SignalRService {
    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseURL}/message`) // URL of your SignalR hub
            // .configureLogging(signalR.LogLevel.Information)
            .build();
    }

    startConnection() {
        this.connection.start().catch(err => console.error(err.toString()));
    }

    // Method to receive messages
    receiveMessage(callback) {
        this.connection.on("Notify", message => {
            callback(message);
        });
    }
}

export default SignalRService;
