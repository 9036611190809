import React, {useEffect, useState} from 'react';
import Table from 'rc-table';
import {reqDeleteGoods, reqGetGoods} from '../../api/good';
import {EditSOutline, FillinOutline} from 'antd-mobile-icons';
import {ActionSheet, Dialog, Image, ImageViewer, SearchBar, Toast} from 'antd-mobile';
import {useNavigate} from 'react-router';
import {baseURL} from '../../api/api';
import {reqGetOrderGoodPhotos} from '../../api/order';

// 商品列表
const GoodList = () => {
    const navigate = useNavigate();

    const [editSelect, setEditSelect] = useState(null);

    const [goods, setGoods] = useState([]);

    useEffect(() => {
        getGoods();
    }, []);
    let timeout2;

    const getGoods = async (query = {}) => {
        const res = await reqGetGoods(query);
        if (res.code !== 200) return;
        let i = 0;
        res.data.forEach(item => {
            item['key'] = i++;
        })
        setGoods(res.data);
    }

    return (
        <div>
            <SearchBar style={{padding: '5px 5px'}}
                       onClear={async () => {
                           await getGoods();
                       }}
                       onChange={(value) => {
                           let query = {};
                           if (value) {
                               query = {goodName: value};
                           }
                           clearTimeout(timeout2);
                           // 设置一个延迟，比如500毫秒
                           timeout2 = setTimeout(async () => {
                               await getGoods(query);
                           }, 200);
                       }}
                       placeholder='商品名称'
                       showCancelButton={() => false} />
            <Table
                emptyText={'暂无数据'}
                columns={[
                    {
                        title: goods.length === 0 ? '商品': `商品(${goods.length})`,
                        dataIndex: 'goodName'
                    },
                    {
                        title: '主图',
                        dataIndex: 'mainPhoto',
                        width: 70,
                        render: (text) =>
                            <Image
                                src={text ? baseURL + '/good/photo/path/' + text : null}
                                style={{ borderRadius: 4 }}
                                fit='cover'
                                lazy={true}
                                onClick={() => {
                                    if (text) {
                                        ImageViewer.Multi.show({ images:[ baseURL + '/good/photo/path/' + text] })
                                    }
                                }}
                                width={70}
                                height={70}
                            />
                    },
                    {
                        title: '单价',
                        dataIndex: 'unitPrice',
                        width: 50,
                        render: (text) =>
                            <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>{text}</div>
                    },
                    {
                        title: ' ',
                        width: 20,
                        render: (_, record) => <FillinOutline color={'#237804'} onClick={() => {
                            setEditSelect(record);
                        }} fontSize={'20px'}/>
                    }
                ]}
                data={goods} />
            <ActionSheet actions={[
                {
                    text: '编辑商品',
                    onClick: () => {
                        const data = editSelect;
                        setEditSelect(null);
                        navigate('/editgood/' + data.id);
                    }
                },
                {
                    text: '删除商品',
                    bold: true,
                    danger: true,
                    onClick: () => {
                        const data = editSelect;
                        setEditSelect(null);
                        Dialog.show({
                            content: '是否删除该商品',
                            closeOnAction: true,
                            actions: [
                                [
                                    {
                                        key: 'cancel',
                                        text: '取消',
                                    },
                                    {
                                        key: 'delete',
                                        text: '删除',
                                        bold: true,
                                        danger: true,
                                        onClick: async () => {
                                            const res = await reqDeleteGoods(data.id);
                                            if (res.code !== 200) return;
                                            Toast.show({
                                                content: '删除成功',
                                            });
                                            await getGoods();
                                        }
                                    },
                                ],
                            ],
                        });
                    }
                }
            ]} onMaskClick={() => {
                setEditSelect(null);
            }} visible={editSelect !== null}
                cancelText={'取消'}
                 onClose={() => {
                     setEditSelect(null);
                 }} />
        </div>
    );
};

export default GoodList;
