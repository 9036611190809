import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {
    Button, Checkbox, CheckList, Dialog,
    Form, Image,
    ImageUploader,
    Input, Radio,
    Selector,
    TextArea,
    Toast,
} from 'antd-mobile';
import {reqAddGood, reqGetGoods, reqUpdateGood, reqUploadPhoto} from '../../api/good';
import {baseURL} from '../../api/api';
import { v4 as uuidv4 } from 'uuid';
import {FileOutline, ShopbagOutline, CloseOutline} from 'antd-mobile-icons';

const GoodEdit = (props) => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const {mode} = props;

    const {id} = useParams();

    const [fileList, setFileList] = useState([]);

    const [mainPhoto, setMainPhoto] = useState(null);

    useEffect(() => {
        form.setFieldsValue('foreignHide', [0]);
        if (id && mode === 'edit') {
            fillForm();
        }
    }, []);

    const fillForm = async () => {
        const res = await reqGetGoods({
            goodIds: id
        });
        if (res.code !== 200 || res.data.length !== 1) return;
        let key = 0;
        if (res.data[0].photos) {
            res.data[0].photos.forEach(item => {
                item['uid'] = uuidv4();
                if (item['mainPhoto'] === 1) {
                    setMainPhoto(item['uid']);
                }
                item['photoPath'] = item.goodPhotoPath;
                item['url'] = baseURL + '/good/photo/path/' +  item.goodPhotoPath;
                item['thumbUrl'] = baseURL + '/good/photo/path/' +  item.goodPhotoPath;
            });
            setFileList(res.data[0].photos);
        }
        form.setFieldsValue(res.data[0]);
        form.setFieldValue('foreignHide', [res.data[0].foreignHide]);
    }
    const handleOk = async () => {
        const data = form.getFieldsValue();
        data['foreignHide'] = form.getFieldValue('foreignHide')[0];
        data['photos'] = fileList;
        if (data.photos && data.photos.length > 0 && mainPhoto) {
            data.photos.forEach(item => {
                if (mainPhoto === item.uid) {
                    item.mainPhoto = 1;
                } else {
                    item.mainPhoto = 0;
                }
            });
        }
        if (data.photos.length > 0 && !mainPhoto) {
            data.photos[0].mainPhoto = 1;
        }
        let res;
        if (mode === 'add') {
            res = await reqAddGood(data);
        } else {
            data['id'] = id;
            res = await reqUpdateGood(data);
        }
        if (res.code !== 200) return;
        Toast.show({
            icon: 'success',
            content: res.msg
        });
        navigate(-1);
    }
    const handleUpload = async (file) => {
        if (!file) {
            return;
        }
        var form = new FormData();
        form.append('file', file);
        const res = await reqUploadPhoto(form);
        if (res.code !== 200) {
            Toast.show({content: res.msg, icon: 'fail'});
            return;
        }

        return {
            uid: uuidv4(),
            mainPhoto: 0,
            photoPath: res.data,
            name: res.data,
            thumbUrl: baseURL + '/good/photo/path/' + res.data,
            url: baseURL + '/good/photo/path/' + res.data
        };
    }

    return (
        <div>
            <Form form={form} footer={
                <Button onClick={handleOk} block color='primary' size='large'>
                    提交
                </Button>
            } layout='horizontal' >
                <Form.Item name={'goodName'} label={'商品名称'}>
                    <Input placeholder={'请输入客户名称'} />
                </Form.Item>
                <Form.Item name={'unitPrice'} label={'单价'}>
                    <Input placeholder={'请输入单价'}/>
                </Form.Item>
                <Form.Item name={'wholePrice'} label='整件价'>
                    <Input type={'number'} placeholder='请输入整件价' />
                </Form.Item>
                <Form.Item name={'kgPrice'} label='每斤价'>
                    <Input type={'number'} placeholder='请输入每斤价' />
                </Form.Item>
                <Form.Item name={'standards'} extra={<div>
                    包
                </div>} label={'规格(整件)'}>
                    <Input placeholder={'请输入规格'}/>
                </Form.Item>
                <Form.Item name={'exp'}  extra={<div>
                        天
                </div>} label={'保质期'}>
                    <Input placeholder={'请输入保质期'}/>
                </Form.Item>
                <Form.Item name={'location'} label={'摆放位置'}>
                    <TextArea placeholder={'请输入摆放位置'} />
                </Form.Item>
                <Form.Item name={'remark'} label={'备注'}>
                    <TextArea placeholder={'请输入备注信息'} />
                </Form.Item>
                <Form.Item name={'foreignHide'} label={'对外隐藏'}>
                    <Selector options={[
                        {
                            label: '不隐藏',
                            value: 0
                        },
                        {
                            label: '隐藏',
                            value: 1
                        }
                    ]} />
                </Form.Item>
                <Radio.Group block value={mainPhoto} onChange={(value) => {
                    setMainPhoto(value);
                }} style={{
                    '--icon-size': '28px',
                    '--font-size': '20px',
                    '--gap': '10px',
                }}>
                    <ImageUploader
                        onDelete={(v) => {
                            if (v?.uid === mainPhoto) {
                                setMainPhoto(null);
                            }
                        }}
                        accept={'.png,.jpg,.jpeg'}
                        value={fileList}
                        onChange={setFileList}
                        upload={handleUpload}
                        renderItem={(originNode, file) => (
                            <Radio value={file.uid}>
                                {originNode}
                            </Radio >
                        )}
                    />
                </Radio.Group>
            </Form>
        </div>
    );
};

export default GoodEdit;
