import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {
    reqGetDeliverNoteRemotePrint,
    reqGetDeliveryNotePrint,
    reqGetRemotePrinterClean,
    reqGetRemotePrinterStatus
} from '../../api/deliverynote';
import {Button, Form, NumberKeyboard, Space, Switch, Toast, VirtualInput} from 'antd-mobile';
import './printDeliveryNote.css';
import { Document, Page,pdfjs  } from "react-pdf";
import {LeftOutline, RightOutline} from 'antd-mobile-icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const MyPdfViewer = (props) => {
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const {pdf} = props;
    const styles = {
        container: {
            width: window.innerWidth - 4,
        },
    };

    return (
        <div style={styles.container}>
            <Space>
                <div>
                    {`第${pageNumber}页 共 ${numPages} 页`}
                </div>
                {
                    numPages > 1 ? <>
                        <button onClick={() => {
                            setPageNumber(pageNumber - 1);
                        }} disabled={pageNumber === 1}>上一页</button>
                        <button onClick={() => {
                            setPageNumber(pageNumber + 1);
                        }} disabled={pageNumber === numPages}>下一页</button>
                    </> : null
                }
            </Space>
            <div style={{borderBottom: '1px solid black', borderTop: '1px solid black'}}>
                <Document file={pdf}
                          onLoadError={() => {
                              Toast.show({
                                  icon: 'fail',
                                  content: '预览失败'
                              })
                          }}
                          onLoadSuccess={({ numPages }) => {
                              setNumPages(numPages);
                          }}>
                    <Page renderAnnotationLayer={false}
                          renderTextLayer={false}
                          width={window.innerWidth  - 4}
                          pageNumber={pageNumber} />
                </Document>
            </div>
        </div>
    );
};

const PrintDeliveryNote = () => {

    const [form] = Form.useForm();
    const handler = useRef();

    const [pdfUrl, setPdfUrl] = useState();
    const {id} = useParams();

    useEffect(() => {
        form.setFieldValue('fontsize', '35');
        form.setFieldValue('hidemoney', true);
        handlePrinterStatus();
        handlePdf();
    }, []);

    const handlePrinterStatus = async() => {
        const res = await reqGetRemotePrinterStatus();
        if (res.code !== 200) return;
        if (res.data.status !== 1) {
            Toast.show({
               icon: 'fail',
               content: '打印机离线'
            });
        }
    }

    const handlePrint = async () => {
        const query = form.getFieldsValue();
        Toast.show({
            icon: 'loading',
            content: '打印中',
            duration: 5000,

        });
        const res = await reqGetDeliverNoteRemotePrint(id,{
            hidemoney: !query.hidemoney ? 1 : null,
            fontsize: query.fontsize
        });
        console.log(res)
        Toast.clear();
        if (res.code !== 200) return;
        Toast.show({
            icon: 'success',
            content: '打印成功',
        });
    }

    const handleCleanPrinter = async () => {
        const res = await reqGetRemotePrinterClean();
        if (res.code !== 200) return;
        Toast.show({content: res.msg});
    }

    const handleDownload = async () => {
        const query = form.getFieldsValue();
        const pdfBlob = await reqGetDeliveryNotePrint(id, {
            hidemoney: !query.hidemoney ? 1 : null,
            fontsize: query.fontsize
        });

        const sampleBlob = new Blob([pdfBlob], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(sampleBlob);

        // 设置下载文件的名称
        downloadLink.download = `销售单.pdf`;

        // 将链接添加到页面，并触发点击
        document.body.appendChild(downloadLink);
        downloadLink.click();
        // 移除下载链接
        document.body.removeChild(downloadLink);
        // 在新窗口中打开Blob URL进行打印
        const page = window.open(URL.createObjectURL(sampleBlob), '_blank');
    }
    const handlePdf = async () => {
        const query = form.getFieldsValue();
        if (query.fontsize - 0 > 90) {
            Toast.show({
                icon: 'fail',
                content: '字体过大'
            });
            form.setFieldValue('fonsize', '35');
            return;
        }
        const pdfBlob = await reqGetDeliveryNotePrint(id, {
            hidemoney: !query.hidemoney ? 1 : null,
            fontsize: query.fontsize
        });
        const sampleBlob = new Blob([pdfBlob], { type: 'application/pdf' });
        // 创建URL
        const pdfUrl = URL.createObjectURL(sampleBlob);
        setPdfUrl(pdfUrl);
    }
    let timeout1;

    // form更新
    const handleFormChange = (all) => {
        clearTimeout(timeout1);
        // 设置一个延迟，比如500毫秒
        timeout1 = setTimeout( () => {
            handlePdf(all);
        }, 500);
    }

    return (
        <div>
            <Form onValuesChange={(_, all) => handleFormChange(all)} form={form} layout='horizontal' style={{'--prefix-width': '2.5em'}}>
                <Form.Item name={'fontsize'} label={'大小'} extra={<div>
                    <Button onClick={() => {
                        form.setFieldValue('fontsize', form.getFieldValue('fontsize') - 0 - 1 + '');
                        handleFormChange(form.getFieldValue());
                    }}  size={'small'} >
                        <LeftOutline />
                    </Button>
                    <Button onClick={() => {
                        form.setFieldValue('fontsize', form.getFieldValue('fontsize') - 0 + 1 + '');
                        handleFormChange(form.getFieldValue());
                    }}  size={'small'} >
                        <RightOutline />
                    </Button>
                </div>}>
                    <VirtualInput
                        placeholder='请输入文字大小'
                        keyboard={<NumberKeyboard confirmText='确定' />}
                    />
                </Form.Item>
                <Form.Item name={'hidemoney'} label={'金额'}>
                    <Switch defaultChecked />
                </Form.Item>
            </Form>
            <Space>
            </Space>
            {
                pdfUrl ? <MyPdfViewer pdf={pdfUrl} /> : null
            }
            <div style={{position:'fixed', display: 'flex',width: '100%',bottom: '0', backgroundColor: 'white'}}>
                <div style={{width: '33.33%'}}>
                    <Button  style={{height: '45px'}} block color={'warning'} onClick={handleCleanPrinter}>
                        重置打印机
                    </Button>
                </div>
                <div style={{width: '33.33%'}}>
                    <Button  style={{height: '45px'}} block color={'success'} onClick={handlePrint}>
                        打印
                    </Button>
                </div>
                <div style={{width: '33.33%'}}>
                    <Button  style={{height: '45px'}} block color={'primary'} onClick={handleDownload}>
                        下载
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PrintDeliveryNote;
