import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {
    reqClearCustomerBill,
    reqDeleteCustomerBillInfo,
    reqGetCustomerBill, reqGetCustomers,
} from '../../api/customer';
import {ActionSheet, Button, Checkbox, Dialog, ImageViewer, Space, Tag, Toast} from 'antd-mobile';
import {FormatMoney} from '../../utils/formatMoney';
import {FillinOutline} from 'antd-mobile-icons';
import Table from 'rc-table';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import {reqDeleteDeliveryNotes} from '../../api/deliverynote';

// 供应商账本
const ManufacturerStatement = () => {

    const {id} = useParams();
    const navigator = useNavigate();

    const [tableData, setTableData] = useState([]);

    const [summary, setSummary] = useState({totals: null, pays: null, originArrears: null});

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [subData, setSubData] = useState([]);

    const [totalRound, setTotalRound] = useState(true);

    const [editSelect, setEditSelect] = useState(null);
    const [imgVisible, setImgVisible] = useState(false);
    const [detailImg, setDetailImg] = useState(null);

    const [customerName, setCustomerName] = useState(null);

    useEffect(() => {
        initPage();
    }, [id, totalRound]);

    const initPage = async () => {
        await initData();
        const res = await reqGetCustomers({
            customerIds: id
        })
        if (res.code !== 200 || res.data.length === 0) return;
        setCustomerName(res.data[0].customerName);
    }

    const initData = async () => {
        const res= await reqGetCustomerBill(id, {totalRound: totalRound ? 1 : 0});
        if (res.code !== 200) return;
        let key = 1;
        res.data.forEach(item => {
            item['key'] = key++;
        });
        setSummary({totals: res.totals, pays: res.pays, originArrears: res.originArrears});
        setTableData(res.data);
    }

    const getSubDatas = (record) =>{
        setSubData(record.items);
    }

    const handleRowClick = (record) => {
        if (record.key === expandedRowKeys[0]) {
            setExpandedRowKeys([]);
            return;
        }
        getSubDatas(record);
        setExpandedRowKeys([record.key]);
    };

    const expandedRowRender = (record) => {
        let columns = [
            {
                title: ' ',
                width: 25,
                render: (text, record, index) => <div style={{whiteSpace: 'nowrap'}}>
                    {index + 1}
                </div>,
            },
            {
                title: '类型',
                dataIndex: 'noteType',
                width: 60,
                render: (text) => <div>
                    {text ? <Tag style={{fontSize: '15px'}} color={text === 1 ? 'danger' : 'success'}>流水</Tag> : <Tag style={{fontSize: '15px'}} color='default'>销售单</Tag>}
                </div>
            },
            {
                title: '应付',
                key: 'noteTotal',
                dataIndex: 'noteTotal',
                width: 80,
                render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                    {FormatMoney(value)}
                </div>
            },
            {
                title: '已付',
                key: 'actualTotal',
                dataIndex: 'actualTotal',
                width: 80,
                render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                    {FormatMoney(value)}
                </div>
            },
            {
                title: ' ',
                width: 20,
                render: (_, record) => <FillinOutline color={'#237804'} onClick={() => {
                    setEditSelect(record);
                }} fontSize={'20px'}/>
            }
        ];

        return <Table data={subData} columns={columns} />
    }

    const save2Img = () => {
        setImgVisible(true);
        const element = document.getElementById('statementtable2'); // 替换为你要保存为图片的元素的ID
        html2canvas(element)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                setDetailImg(imgData);
            });
    }

    const generateSummary = () => {
        if (summary.originArrears && summary.pays) {
            return `合计应付: ${FormatMoney(summary.originArrears)} - ${FormatMoney(summary.pays)} = ${FormatMoney(summary.totals)}`;
        }

        return  `合计应付: ${FormatMoney(summary.totals)}`;
    }

    return (
        <div>
            <div>
                <Checkbox onClick={() => {
                    setTotalRound(!totalRound);
                }} checked={totalRound}>
                    四舍五入
                </Checkbox>
            </div>
            <div id={'statementtable2'}>
                <Space block justify={'between'} style={{fontSize: '18px', padding: '5px 0'}}>
                    <div >
                        供货商: {customerName}
                    </div>

                </Space>
                <Table
                    data={tableData}
                    footer={() => [<div style={{fontWeight: 'bold', fontSize: '18px'}}>
                        {generateSummary()}
                    </div>]}
                    emptyText={'暂无欠账'}
                    expandable={{
                        expandRowByClick: false,
                        expandedRowKeys,
                        onExpand: (v, record) => {
                            handleRowClick(record)
                        },
                        expandedRowRender
                    }}
                    columns={[
                        {
                            title: ' ',
                            dataIndex: 'key',
                            width: 50
                        },
                        {
                            title: '日期',
                            dataIndex: 'invoiceTime',
                            width: 120,
                            render: (text) =>
                                dayjs(text).format('YYYY-MM-DD')
                        },
                        {
                            title: '应付',
                            dataIndex: 'arrears',
                            render: (text) =>
                                <div style={{textAlign: 'right'}}>
                                    {FormatMoney(text)}
                                </div>
                        },
                        // {
                        //     title: '销售单',
                        //     render: (_, record) => <div onClick={async() => {
                        //
                        //     }} style={{textAlign: 'right'}}>
                        //         {record.items.filter(item => item.noteType === null).length}
                        //     </div>
                        // },
                        {
                            title: '流水',
                            render: (_, record) => <div onClick={() => {
                                Toast.show({content: '123'});
                            }}  style={{textAlign: 'right'}}>
                                {record.items.filter(item => item.noteType !== null).length ? record.items.filter(item => item.noteType !== null).length : null}
                            </div>
                        }
                    ]}
                />
            </div>
            <ActionSheet
                cancelText='取消'
                visible={editSelect}
                actions={[
                    {
                        text: editSelect?.noteType ? '编辑流水' : '销售单详情',
                        onClick: () => {
                            if (editSelect?.noteType) {
                                navigator('/editbill/' + editSelect.id);
                                return;
                            }
                            navigator('/detail/' + editSelect.id);
                        }
                    },
                    {
                        text: editSelect?.noteType ? '删除流水' : '删除销售单',
                        danger: true,
                        bold: true,
                        onClick: () => {
                            const data = editSelect;
                            setEditSelect(null);
                            const key = expandedRowKeys;
                            Dialog.show({
                                content: data?.noteType ?  '是否删除该流水?' : '是否删除该销售单?',
                                closeOnAction: true,
                                actions: [
                                    [
                                        {
                                            key: 'cancel',
                                            text: '取消',
                                        },
                                        {
                                            key: 'delete',
                                            text: '删除',
                                            bold: true,
                                            danger: true,
                                            onClick: async () => {
                                                if (data?.noteType) {
                                                    const res = await reqDeleteCustomerBillInfo(data.id);
                                                    if (res.code !== 200) return;
                                                    await initPage();
                                                    setExpandedRowKeys(key);
                                                    return;
                                                }
                                                const res = await reqDeleteDeliveryNotes(data.id);
                                                if (res.code !== 200) return;
                                                await initPage();
                                                setExpandedRowKeys(key);
                                                return;
                                            }
                                        },
                                    ],
                                ],
                            });
                        }
                    }
                ]}
                onClose={() => setEditSelect(null)}
            />
            <ImageViewer
                classNames={{
                    mask: 'customize-mask',
                    body: 'customize-body',
                }}
                image={detailImg}
                renderFooter={(img) => {
                    return <div className={'vfooter'}>
                        <div onClick={() => {
                            var downloadLink = document.createElement("a");
                            downloadLink.href = img;
                            // 设置下载文件的名称
                            downloadLink.download = `对账单.png`;

                            // 将链接添加到文档中并模拟点击
                            document.body.appendChild(downloadLink);
                            downloadLink.click();

                            // 清理
                            document.body.removeChild(downloadLink);
                        }} className={'vfooterButton'}>
                            下载图片
                        </div>
                    </div>
                }}
                visible={imgVisible}
                onClose={() => {
                    setImgVisible(false);
                }}
            />
            <div style={{position:'fixed', width: '100%',bottom: '0', backgroundColor: 'white', display: 'flex'}}>
                <Button onClick={save2Img} style={{height: '45px', width:'50%'}} color={'primary'} >
                    图片
                </Button>
                <Button style={{height: '45px', width:'50%'}} block onClick={async () => {
                    Dialog.confirm({
                        content:'确认清账？清账后无法撤回',
                        onConfirm: async () => {
                            const res = await reqClearCustomerBill(id);
                            if (res.code !== 200) return;
                            Toast.show({
                                content: '清账成功'
                            });
                            navigator(-1);
                        }
                    });
                }}  size={'small'} color={'success'}>
                    清账
                </Button>
            </div>
        </div>
    );
};

export default ManufacturerStatement;
