import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router';
import {
    Badge,
    Button, DatePicker,
    Dialog,
    Form,
    Input,
    NavBar,
    NumberKeyboard,
    Selector,
    Space,
    TabBar,
    Toast,
    VirtualInput
} from 'antd-mobile';
import {
    ReceivePaymentOutline,
    TeamOutline,
    HistogramOutline,
    UserOutline,
    ShopbagOutline
} from 'antd-mobile-icons';
import AddDeliveryNote from '../deliverynotes/addDeliveryNote';
import DeliveryNoteList from '../deliverynotes/deliveryNoteList';
import Customer from '../customer/customer';
import Me from '../me/me';
import GoodList from '../good/goodList';
import DeliveryNoteDetail from '../deliverynotes/deliveryNoteDetail';
import EditDeliveryNote from '../deliverynotes/editDeliveryNote';
import PrintDeliveryNote from '../deliverynotes/printDeliveryNote';
import CustomerEdit from '../customer/customerEdit';
import GoodEdit from '../good/goodEdit';
import Statement from '../statement/statement';
import dayjs from 'dayjs';
import BillEdit from '../statement/billEdit';
import ManufacturerStatement from '../statement/manufacturerStatement';
import ManufacturerEdit from '../manufacturer/manufacturerEdit';
import Manufacturer from '../manufacturer/manufacturer';
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {baseURL} from '../../api/api';
import SignalRService from '../../utils/SignalRService';
import {reqGetBooks} from '../../api/customer';
import me from '../me/me';

const Home = () => {

    const [form1] = Form.useForm();
    const [backText, setBackText] = useState(null);
    const [header, setHeader] = useState();
    const [footer, setFooter] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [rightBtn, setRightBtn] = useState(null);
    const [leftBtn, setLeftBtn] = useState(null);
    const [changeWidth, setChangeWidth] = useState(!!localStorage.getItem('changeWidth'));
    const signalRService = new SignalRService();
    const [messages, setMessages] = useState(null);

    const [messageNumber, setMessageNumber] = useState(0);

    useEffect(() => {
        signalRService.startConnection();

        signalRService.receiveMessage(async (message) => {
            const obj = JSON.parse(message);
            if (obj?.customerId) {
                setMessages(obj);
                await getBooksNumber();
            }
        });

        return () => {
            signalRService.connection.off("Notify");
        };
    }, [messages]);

    const getBooksNumber = async () => {
        const res = await reqGetBooks({status: 0});
        if (res.code === 200) {
            setMessageNumber(res.data.length);
        }
    }

    useEffect(() => {
        handleRouterChange();
    }, [location, changeWidth]);
    const handleRouter = (value) => {
        navigate(value);
    }

    const handleRouterChange = () => {
        const index = tabs.findIndex(item => item.key === '/' + location['pathname'].split('/')[1]);
        if (index === -1) {
            setFooter(true);
            setBackText(null);
            setHeader('嘉伟商行');
            return;
        }
        setRightBtn(tabs[index].right);
        setHeader(tabs[index].header);
        setFooter(tabs[index].footer);
        if (tabs[index].main) {
            setBackText(null);
            return;
        }
        setBackText('返回');
    }

    const tabs = [
        {
            key: '/',
            title: '开单',
            header: '嘉伟商行',
            footer: true,
            path: '/',
            element: <AddDeliveryNote changeWidth={changeWidth} />,
            icon: <Badge style={{'--top': '20%'}} content={messages ? '新订单' : ''}>
                <ReceivePaymentOutline />
            </Badge>,
            main: true,
            right: <div style={{paddingRight: '5px'}}>
                <Button onClick={() => {
                    setChangeWidth(!changeWidth);
                    localStorage.setItem('changeWidth', !changeWidth);
                }} fill={changeWidth ? 'solid' : 'outline'} size={'small'} color={'success'}>
                    宽屏模式
                </Button>
            </div>,
        },
        {
            key: '/delivery',
            title: '账本',
            header: '账本',
            footer: true,
            path: '/delivery',
            element: <DeliveryNoteList />,
            icon: <HistogramOutline />,
            main: true
        },
        {
            key: '/customer',
            title: '客户',
            header: '客户',
            path: '/customer',
            footer: true,
            element: <Customer />,
            icon: <TeamOutline />,
            main: true,
            right: <div style={{paddingRight: '5px'}}>
                    <Button onClick={() => {
                        navigate('/addcustomer');
                    }} size={'small'} color={'success'}>
                        添加客户
                    </Button>
            </div>
        },
        {
            key: '/editcustomer',
            title: '编辑客户信息',
            header: '编辑客户信息',
            footer: false,
            path: '/editcustomer/:id',
            element: <CustomerEdit mode={'edit'} />
        },
        {
            key: '/addcustomer',
            title: '添加客户信息',
            header: '添加客户信息',
            path: '/addcustomer',
            footer: false,
            element: <CustomerEdit mode={'add'} />
        },
        {
            key:'/good',
            title: '商品',
            header: '商品',
            footer: true,
            path: '/good',
            element: <GoodList />,
            icon: <ShopbagOutline />,
            main: true,
            right: <div style={{paddingRight: '5px'}}>
                <Button onClick={() => {
                    navigate('/addgood');
                }} size={'small'} color={'success'}>
                    添加商品
                </Button>
            </div>
        },
        {
            key: '/editgood',
            title: '编辑商品信息',
            header: '编辑商品信息',
            footer: false,
            path: '/editgood/:id',
            element: <GoodEdit mode={'edit'} />
        },
        {
            key: '/addgood',
            title: '添加商品信息',
            header: '添加商品信息',
            path: '/addgood',
            footer: false,
            element: <GoodEdit mode={'add'} />
        },
        {
            key: '/me',
            path: '/me',
            title: '我的',
            header: '我的',
            footer: true,
            element: <Me />,
            icon: <UserOutline />,
            main: true
        },
        {
            key: '/manufacturer',
            title: '供应商',
            header: '供应商',
            path: '/manufacturer',
            element: <Manufacturer />,
            right: <div style={{paddingRight: '5px'}}>
                <Button onClick={() => {
                    navigate('/addmanufacturer');
                }} size={'small'} color={'success'}>
                    添加供应商
                </Button>
            </div>
        },
        {
            key: '/editmanufacturer',
            title: '编辑供应商信息',
            header: '编辑供应商信息',
            footer: false,
            path: '/editmanufacturer/:id',
            element: <ManufacturerEdit mode={'edit'} />
        },
        {
            key: '/addmanufacturer',
            title: '添加供应商信息',
            header: '添加供应商信息',
            path: '/addmanufacturer',
            footer: false,
            element: <ManufacturerEdit mode={'add'} />
        },
        {
            key: '/statement',
            path: '/statement/:id',
            title: '对账单详情',
            header: '对账单详情',
            footer: false,
            element: <Statement />,
            right: <div style={{paddingRight: '5px'}}>
                <Button onClick={() => {
                    navigate('/addbill/' +location.pathname.replace('/statement/', ''));
                }} size={'small'} color={'success'}>
                    添加流水
                </Button>
            </div>
        },
        {
            key: '/manufacturerstatement',
            path: '/manufacturerstatement/:id',
            title: '供应商对账单详情',
            header: '供应商对账单详情',
            footer: false,
            element: <ManufacturerStatement />,
            right: <div style={{paddingRight: '5px'}}>
                <Button onClick={() => {
                    navigate('/addbill/' +location.pathname.replace('/manufacturerstatement/', ''));
                }} size={'small'} color={'success'}>
                    添加流水
                </Button>
            </div>
        },
        {
            key: '/editbill',
            title: '编辑流水信息',
            header: '编辑流水信息',
            footer: false,
            path: '/editbill/:id',
            element: <BillEdit mode={'edit'} />
        },
        {
            key: '/addbill',
            title: '添加流水信息',
            header: '添加流水信息',
            path: '/addbill/:id',
            footer: false,
            element: <BillEdit mode={'add'} />
        },
        {
            key: '/detail',
            path: '/detail/:id',
            title: '账单详情',
            header: '账单详情',
            footer: false,
            element: <DeliveryNoteDetail />
        },
        {
            key: '/edit',
            path: '/edit/:id',
            title: '编辑销售单',
            header: '编辑销售单',
            footer: false,
            element: <EditDeliveryNote changeWidth={changeWidth} />,
            right: <div style={{paddingRight: '5px'}}>
                <Button onClick={() => {
                    setChangeWidth(!changeWidth);
                    localStorage.setItem('changeWidth', !changeWidth);
                }} fill={changeWidth ? 'solid' : 'outline'} size={'small'} color={'success'}>
                    宽屏模式
                </Button>
            </div>
        },
        {
            key: '/print',
            path: '/print/:id',
            title: '打印预览',
            header: '打印预览',
            footer: false,
            element: <PrintDeliveryNote />
        }
    ]

    return (
        <div>
            <NavBar style={{position:'fixed', padding: 0, width: '100%', backgroundColor: '#f0f0f0', zIndex: 100, top: 0}}
                    back={backText}
                    right={rightBtn}
                    left={leftBtn}
                    onBack={() => {
                        navigate(-1);
                    }}><div style={{fontWeight: 'bold'}}>
                {header}
            </div></NavBar>
            <div style={{overflowY: 'auto',paddingTop: '50px', paddingBottom: '50px'}}>
                <Routes>
                    {
                        tabs.map(item => (
                            <Route key={item.key} path={item.path} element={item.element} />
                        ))
                    }
                </Routes>
            </div>
            {
                footer ? <TabBar  activeKey={location['pathname']}
                        onChange={(value) => handleRouter(value)}
                        style={{position:'fixed', width: '100%',bottom: '0', backgroundColor: 'white'}}>
                    {
                        tabs.map(item => {
                            if (item.main)
                                return (
                                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                                )
                        })
                    }
                </TabBar> : null
            }
        </div>
    );
};

export default Home;
