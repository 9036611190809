import React, {useEffect, useState} from 'react';
import {
    ActionSheet,
    Button, CheckList,
    DatePicker,
    Dialog,
    DotLoading, ErrorBlock,
    Form,
    Input,
    Modal, NumberKeyboard,
    Popup, SearchBar, Selector,
    Space,
    SpinLoading, Tag, TextArea,
    Toast, VirtualInput
} from 'antd-mobile';
import {
    reqConfirmDeliveryMoney,
    reqDeleteDeliveryNotes,
    reqGetDeliveryNoteDates,
    reqGetDeliveryNotes
} from '../../api/deliverynote';
import dayjs from 'dayjs';
import {FormatMoney} from '../../utils/formatMoney';
import Table from 'rc-table';
import {
    FillinOutline,
    CloseCircleOutline
} from 'antd-mobile-icons';
import styles from './addDeliveryNote.css';
import {reqGetCustomers} from '../../api/customer';
import {useNavigate} from 'react-router';

const NumberKeyboardWithContainer = (
    props,
) => {
    return <NumberKeyboard customKey={['.']} confirmText={'确定'} {...props} getContainer={props.container} />;
};

const DeliveryNoteList = () => {
    const [form1] = Form.useForm();

    const [dateList, setDateList] = useState([]);
    const [sumList, setSumList] = useState({noteTotal: null, actualTotal: null});
    const [dateVisible1, setDateVisible1] = useState(false);
    const [dateVisible2, setDateVisible2] = useState(false);
    const [mainLoading, setMainLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [subData, setSubData] = useState([]);
    const [editSelect, setEditSelect] = useState(null);
    const [customerVisible, setCustomerVisible] = useState(null);
    const [customers, setCustomers] = useState([]);
    const navigator = useNavigate();

    const [query, setQuery] = useState({
        startTime: dayjs().startOf('month'),
        endTime: dayjs().endOf('month').add(1, 'second'),
        customerId: null,
        customerName: null
    });

    useEffect(() => {
        getDeliveryDate();
    }, []);

    // 获取客户
    const getCustomers = async (query = {}) => {
        const res = await reqGetCustomers(query);
        if (res.code !== 200) return;
        setCustomers(res.data);
    }

    const getDeliveryDate = async () => {
        setMainLoading(true);
        const res = await reqGetDeliveryNoteDates({
            startTime: query.startTime.format(),
            endTime: query.endTime.format(),
            customerIds: query.customerId !== null ? [query.customerId] : []
        });
        setExpandedRowKeys([]);
        setMainLoading(false);
        if (res.code !== 200) return;
        let key = 0;
        res.data.forEach(item => {
            item['key'] = key++;
        });
        setSumList({actualTotal: res.actualTotal, noteTotal: res.noteTotal});
        setDateList(res.data);
    }

    const getSubDatas = async (record) => {
        const res = await reqGetDeliveryNotes({
            startTime: dayjs(record.date).format(),
            endTime: dayjs(record.date).format(),
            customerIds: query.customerId !== null ? [query.customerId] : [],
            pageSize: 1000
        });
        if (res.code !== 200) return;
        let key = 0;
        res.data.forEach(item => {
            item['key'] = key++;
        })
        setSubData(res.data);
    }

    const handleRowClick = async (record) => {
        if (record.key === expandedRowKeys[0]) {
            setExpandedRowKeys([]);
            return;
        }
        await getSubDatas(record);
        setExpandedRowKeys([record.key]);
    };

    const expandedRowRender = (record) => {
        return <Table data={subData} columns={[
            {
                title: ' ',
                width: 25,
                render: (text, record, index) => <div style={{whiteSpace: 'nowrap'}}>
                    {index + 1}
                </div>,
            },
            // {
            //     title: '时间',
            //     dataIndex: 'createTime',
            //     width: 60,
            //     render: (text) => <div>
            //         {dayjs(text).format('HH:mm')}
            //     </div>
            // },
            {
                title: '状态',
                dataIndex: 'confirmStatus',
                width: 60,
                render: (text) => <div>
                    {text === 1 ? <Tag style={{fontSize: '15px'}} color='success'>已结</Tag> : <Tag style={{fontSize: '15px'}} color='danger'>未结</Tag>}
                </div>
            },
            {
                title: '客户名',
                dataIndex: 'customerName',
            },
            {
                title: '应收',
                key: 'noteTotal',
                dataIndex: 'noteTotal',
                width: 80,
                render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                    {FormatMoney(value)}
                </div>
            },
            {
                title: '已收',
                key: 'actualTotal',
                dataIndex: 'actualTotal',
                width: 80,
                render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                    {FormatMoney(value)}
                </div>
            },
            {
                title: ' ',
                width: 20,
                render: (_, record) => <FillinOutline color={'#237804'} onClick={() => {
                    setEditSelect(record);
                }} fontSize={'20px'}/>
            }
        ]} />
    }
    let timeout1;

    return (
        <div>
            <Form layout='horizontal' style={{'--prefix-width': '2.5em'}}>
                <Form.Item label={'日期'}>
                    <Space align={'start'} justify={'center'}>
                        <Button size={'small'} onClick={() => {
                            setDateVisible1(true);
                        }}>
                            {query.startTime.format('YYYY-M-DD')}
                        </Button>
                            至
                        <Button size={'small'} onClick={() => {
                            setDateVisible2(true);
                        }}>
                            {query.endTime.format('YYYY-M-DD')}
                        </Button>
                        <DatePicker
                            value={query.startTime.toDate()}
                            visible={dateVisible1}
                            onClose={() => {
                                setDateVisible1(false)
                            }}
                            max={query.endTime.toDate()}
                            onConfirm={async (v) => {
                                query.startTime = dayjs(v);
                                setQuery(query);
                                await getDeliveryDate();
                            }}
                        />
                        <DatePicker
                            visible={dateVisible2}
                            value={query.endTime.toDate()}
                            onClose={() => {
                                setDateVisible2(false)
                            }}
                            onConfirm={async (v) => {
                                query.endTime = dayjs(v);
                                setQuery(query);
                                await getDeliveryDate();
                            }}
                        />
                    </Space>
                </Form.Item>
                <Form.Item label={'客户'}>
                    <Space>
                        <div onClick={async () => {
                            await getCustomers();
                            setCustomerVisible(true);
                        }}>
                            <Input value={query.customerName}
                                   onlyShowClearWhenFocus={false}
                                   clearable
                                   readOnly
                                   placeholder={'筛选客户'} />
                        </div>
                        {
                            query.customerName ?
                                <CloseCircleOutline onClick={async () => {
                                    query.customerId = null;
                                    query.customerName = '';
                                    setQuery(query);
                                    await getDeliveryDate();
                                }} /> : null
                        }
                    </Space>
                </Form.Item>
            </Form>
            {
                mainLoading ? <DotLoading  style={{height: '80vh'}} /> :
                    <Table
                        expandable={{
                            expandRowByClick: false,
                            expandedRowKeys,
                            onExpand: (v, record) => {
                                handleRowClick(record)
                            },
                            expandedRowRender
                        }}
                        emptyText={'暂无数据'}
                        data={dateList}

                        columns={[
                            {
                                title: '日期',
                                key: 'date',
                                dataIndex: 'date',
                                width: 90,
                                render: (value) => <div>
                                    {dayjs(value).format('YYYY-MM-DD')}
                                </div>
                            },
                            {
                                title: sumList.noteTotal ? `应收 (${FormatMoney(sumList.noteTotal)})` : '应收',
                                key: 'noteTotal',
                                dataIndex: 'noteTotal',
                                width: 100,
                                render: (value) => <div style={{textAlign: 'right'}}>
                                    {FormatMoney(value)}
                                </div>
                            },
                            {
                                title: sumList.actualTotal ? `已收 (${FormatMoney(sumList.actualTotal)})` : '已收',
                                key: 'actualTotal',
                                dataIndex: 'actualTotal',
                                width: 100,
                                render: (value) => <div style={{textAlign: 'right'}}>
                                    {FormatMoney(value)}
                                </div>
                            }
                        ]} />
            }
            <ActionSheet actions={[
                {
                    text: '账单详情',
                    onClick: () => {
                        const data = editSelect;
                        setEditSelect(null);
                        navigator(`/detail/${data.id}`);
                    }
                },
                {
                    text: '结算此账单',
                    bold: true,
                    onClick: () => {
                        const data = editSelect;
                        setEditSelect(null);
                        const key = expandedRowKeys;
                        form1.setFieldValue('confirmStatus', [data.confirmStatus]);
                        form1.setFieldValue('remark', data.remark);
                        if (data.actualTotal) {
                            form1.setFieldValue('actualTotal', data.actualTotal + '');
                        }
                        Dialog.confirm({
                            onConfirm: async () => {
                                const p = form1.getFieldsValue();
                                if (p.confirmStatus === undefined) {
                                    p.confirmStatus = [0];
                                }
                                if (p.actualTotal) {
                                    p.actualTotal = p.actualTotal - 0;
                                }
                                p.confirmStatus = p.confirmStatus[0];
                                p['id'] = data.id;
                                const res = await reqConfirmDeliveryMoney(p);
                                if (res.code !== 200) return;
                                Toast.show({
                                    content: '更新成功',
                                });
                                await getDeliveryDate();

                                await getSubDatas({
                                    date: dayjs(data.invoiceTime),
                                    customerIds: query.customerIds
                                });

                                setExpandedRowKeys(key);
                            },
                            confirmText: '保存',
                            onClose: () => {
                                form1.resetFields();
                            },
                            content: <div>
                                <Form form={form1} requiredMarkStyle='asterisk' layout='horizontal' mode='card'>
                                    <Form.Item  label={'账单应收金额'}>
                                        <div onClick={() => {
                                            Toast.show({
                                                content: '应收为实时计算 不可修改',
                                                icon: 'fail'
                                            });
                                        }}>{FormatMoney(data.noteTotal)}</div>
                                    </Form.Item>
                                    <Form.Item name={'actualTotal'} label={'实收金额'}>
                                        <VirtualInput
                                            onChange={(value) => {
                                                try {
                                                    if (!value.includes('.')) {
                                                        value = (value - 0).toString();
                                                    }
                                                    if (value.endsWith('.') && value.substring(0, value.length - 1).toString().includes('.') === true) {
                                                        value = value.substring(0, value.length - 1);
                                                    }
                                                    if (value.startsWith('.')) {
                                                        value = '0.';
                                                    }
                                                    form1.setFieldValue('actualTotal', value);
                                                } catch {

                                                }
                                            }}
                                            keyboard={<NumberKeyboardWithContainer  />}
                                        />
                                    </Form.Item>
                                    <Form.Item name={'confirmStatus'} label={'是否结算'}>
                                        <Selector
                                            options={[
                                                {
                                                    label: '已结算',
                                                    value: 1,
                                                },
                                                {
                                                    label: '未结算',
                                                    value: 0,
                                                },
                                            ]}
                                            defaultValue={[0]}
                                        />
                                    </Form.Item>
                                    <Form.Item name={'remark'} label={'备注'}>
                                        <TextArea placeholder={'备注'} />
                                    </Form.Item>
                                </Form>
                            </div>,
                            title: '结算账单'
                        })
                    }
                },
                {
                    text: '编辑账单',
                    onClick: (re) => {
                        const data = editSelect;
                        setEditSelect(null);
                        navigator(`/edit/${data.id}`)
                    }
                },

                {
                    text: '删除账单',
                    bold: true,
                    danger: true,
                    onClick: () => {
                        const data = editSelect;
                        setEditSelect(null);
                        const key = expandedRowKeys;
                        Dialog.show({
                            content: '是否删除该订单',
                            closeOnAction: true,
                            actions: [
                                [
                                    {
                                        key: 'cancel',
                                        text: '取消',
                                    },
                                    {
                                        key: 'delete',
                                        text: '删除',
                                        bold: true,
                                        danger: true,
                                        onClick: async () => {
                                            const res = await reqDeleteDeliveryNotes(data.id);
                                            if (res.code !== 200) return;
                                            Toast.show({
                                                content: '删除成功',
                                            });

                                            await getDeliveryDate();

                                            await getSubDatas({
                                                date: dayjs(data.invoiceTime),
                                                customerIds: query.customerIds
                                            });
                                            setExpandedRowKeys(key);

                                        }
                                    },
                                ],
                            ],
                        });
                    }
                }
            ]} onMaskClick={() => {
                setEditSelect(null);
            }} visible={editSelect !== null}
                 cancelText={'取消'}
                onClose={() => {
                    setEditSelect(null);
                }}/>
            <Popup
                visible={customerVisible}
                onMaskClick={() => {
                    setCustomerVisible(false);
                }}
                destroyOnClose
            >
                <div style={{padding: '10px 10px'}}>
                    <SearchBar
                        placeholder='输入文字过滤选项'
                        onChange={(value) => {
                            let query = {};
                            if (value) {
                                query = {customerName: value};
                            }
                            clearTimeout(timeout1);
                            // 设置一个延迟，比如500毫秒
                            timeout1 = setTimeout(async () => {
                                await getCustomers(query);
                            }, 500);
                        }}
                    />
                </div>
                <div style={{height: '50vh', overflowY: 'scroll'}}>
                    <CheckList
                        value={[query.customerId]}
                        className={styles.myCheckList}
                        onChange={async (val) => {
                            if (val.length === 0) return;
                            const index = customers.findIndex(item => item.id === val[0]);
                            if (index === -1) {
                                return;
                            }
                            query.customerId =  val[0];
                            query.customerName = customers[index].customerName;
                            setQuery(query);
                            setCustomerVisible(false);
                            await getDeliveryDate();
                        }}
                    >
                        {
                            customers.length !== 0 ? customers.map(item => (
                                <CheckList.Item key={item.id} value={item.id}>
                                    {item.customerName}
                                </CheckList.Item>
                            )): <ErrorBlock status='empty' />
                        }
                    </CheckList>
                </div>
            </Popup>
        </div>
    );
};

export default DeliveryNoteList;
