import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {
    Button, CenterPopup, Checkbox, CheckList,
    DatePicker,
    Dialog, ErrorBlock,
    Form,
    Input, Modal, NavBar,
    NumberKeyboard, Popup, SearchBar,
    Space,
    TextArea,
    Toast,
    VirtualInput
} from 'antd-mobile';
import dayjs from 'dayjs';
import {reqGetCustomers} from '../../api/customer';
import {reqAddGood, reqGetGoodRecentPrice, reqGetGoods} from '../../api/good';
import {v4 as uuidv4} from 'uuid';
import {FormatMoney} from '../../utils/formatMoney';
import {CloseOutline, LeftOutline, RightOutline} from 'antd-mobile-icons';
import {
    reqGetDeliveryNote,
    reqUpdateDeliveryNote
} from '../../api/deliverynote';
import Table from 'rc-table';
import styles from './addDeliveryNote.css';
import Scan from '../scan/scan';
import {returnGoodPrice} from '../../utils/returnGoodPrice';

const EditDeliveryNote = (props) => {

    const {id} = useParams();
    const [addForm] = Form.useForm();
    const [addGoodForm] = Form.useForm();
    const navigator = useNavigate();
    const [dateVisible, setDateVisible] = useState(false);
    const [customerVisible, setCustomerVisible] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [goodVisible, setGoodVisible] = useState(false);
    const [goods, setGoods] = useState([]);
    const [detailData, setDetailData] = useState([]);
    const [scanVisible, setScanVisible] = useState(false);
    const [form, setForm] = useState({
        invoiceTime: dayjs(),
        customerId: null,
        customerName: null,
        totalRound: null
    });
    const {changeWidth} = props;

    useEffect(() => {
        getDetail();
        if (changeWidth) {
            getGoods();
        }
    }, [changeWidth]);

    const getDetail = async () => {
        const res = await reqGetDeliveryNote(id);
        if (res.code !== 200) return;
        setForm({
           invoiceTime: dayjs(res.data.invoiceTime),
            customerId: res.data.customerId,
            customerName: res.data.customerName,
            totalRound: res.data.totalRound
        });
        res.data.items.forEach(item => {
            item.uuid = uuidv4();
            item.subTotal = item.number * item.unitPrice;
            if (res.data.totalRound === 1) {
                item.subTotal = Math.round(item.subTotal);
            }
            item.number += '';
            item.unitPrice += '';
        });
        setDetailData(res.data.items);
    }

    // 获取客户
    const getCustomers = async (query = {}) => {
        const res = await reqGetCustomers(query);
        if (res.code !== 200) return;
        setCustomers(res.data);
    }

    // 获取商品
    const getGoods = async (query = {}) => {
        query['simpleMode'] = 1;
        const res = await reqGetGoods(query);
        if (res.code !== 200) return;
        setGoods(res.data);
    }

    // 删除开单行
    const handleClickDeleteDetailData = (record) => {
        setDetailData(detailData.filter(item => item.uuid !== record.uuid));
    }

    // 单价修改
    const handleUnitPriceChange = (value, key) => {
        // 在这里更新数据状态
        const newData = [...detailData];
        const index = newData.findIndex(item => key === item.uuid);
        if (index > -1) {
            newData[index].unitPrice = value;
            newData[index].subTotal = value * (newData[index].number - 0);
            if (form.totalRound === 1) {
                newData[index].subTotal = Math.round(newData[index].subTotal);
            }
            setDetailData(newData);
        }
    };

    const handleNumberChange = (value, key) => {
        // 在这里更新数据状态
        const newData = [...detailData];
        const index = newData.findIndex(item => key === item.uuid);
        if (index > -1) {
            newData[index].number = value;
            newData[index].subTotal = (value - 0) * (newData[index].unitPrice - 0);
            if (form.totalRound === 1) {
                newData[index].subTotal = Math.round(newData[index].subTotal);
            }
            setDetailData(newData);
        }
    }

    // 添加开单行
    const handleClickAddDetailData = (good, standard, number = 1, price = 0) => {
        if (!good) {
            Toast.show({
                icon: 'fail',
                content: '没有匹配项',
            });
            return;
        }
        if (detailData.filter(item => good.id === item.goodId && standard === item.standard ).length > 0) {
            const updateData = detailData.map(item => {
                if (item.goodId === good.id && standard === item.standard) {
                    item.unitPrice ??= 0;
                    let subTotal = (item.number - 0 + number) * (item.unitPrice - 0);
                    if (form.totalRound === 1) {
                        subTotal = Math.round(subTotal);
                    }
                    // 如果对象的id匹配，更新age属性
                    return { ...item, number: `${item.number - 0 + number}`, subTotal: subTotal, standard};
                }
                // 对于其他对象，保持不变
                return item;
            });
            setDetailData(updateData);
            return;
        }
        good.unitPrice = price;
        good.unitPrice ??= 0;
        let subTotal = (good.unitPrice - 0) * number;
        if (form.totalRound === 1) {
            subTotal = Math.round(subTotal);
        }
        setDetailData([...detailData, {
            uuid: uuidv4(),
            goodId: good.id,
            unitPrice: `${good.unitPrice}`,
            number: `${number}`,
            subTotal: subTotal,
            goodName: good.goodName,
            standard
        }]);
    }

    const sumDetailData = () => {
        const result = {
            sum: 0,
            totalNumber: 0
        }
        for (let i = 0; i < detailData.length; i++) {
            result.sum += detailData[i].subTotal;
            result.totalNumber += (detailData[i].number - 0);
        }
        return result;
    }

    const columns = [
        {
            title: detailData.length === 0 ? '商品': `商品(${detailData.length})`,
            dataIndex: 'goodId',
            key: 'goodId',
            render: (text, record) => {
                return <div >{record.goodName}</div>
            }
        },
        {
            title: '单价',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            width: 60,
            render: (text, record) =>
                <VirtualInput value={text}
                              style={{fontSize: '14px'}}
                              onChange={(value) => {
                                  try {
                                      if (value.endsWith('-')) {
                                          value = value.substring(0, value.length - 1);
                                          value = -(value - 0) + '';
                                      }
                                      if (!value.includes('.')) {
                                          value = (value - 0).toString();
                                      }
                                      if (value.endsWith('.') && text.toString().includes('.') === true) {
                                          value = value.substring(0, value.length - 1);
                                      }
                                      if (value.startsWith('.')) {
                                          value = '0.';
                                      }
                                  } catch {

                                  }
                                  handleUnitPriceChange(value, record.uuid);
                              }}
                              keyboard={<NumberKeyboard
                                  confirmText='确定'
                                  customKey={['.', '-']}
                              />}
                />
        },
        {
            title: '数量',
            dataIndex: 'number',
            key: 'number',
            width: 50,
            render: (text, record) =>
                <VirtualInput value={text}
                              style={{fontSize: '14px'}}
                              onChange={(value) => {
                                  try {
                                      if (!value.includes('.')) {
                                          value = (value - 0).toString();
                                      }
                                      if (value.endsWith('.') && text.toString().includes('.') === true) {
                                          value = value.substring(0, value.length - 1);
                                      }
                                      if (value.startsWith('.')) {
                                          value = '0.';
                                      }
                                  } catch {

                                  }
                                  handleNumberChange(value, record.uuid);
                              }}
                              keyboard={<NumberKeyboard
                                  confirmText='确定'
                                  customKey={['.']}
                              />}
                />
        },
        {
            title: ' ',
            width: 40,
            dataIndex: 'standard',
        },
        {
            title: '小计',
            dataIndex: 'subTotal',
            key: 'subTotal',
            width: 80,
            render: (text, _) => <div style={{textAlign: 'right'}}>
                {FormatMoney(text)}
            </div>
        },
        {
            title: ' ',
            dataIndex: '',
            key: 'operations',
            fixed: 'right',
            width: 30,
            render: (text, record) => <Space style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
                                             justify={'center'}
                                             align={'center'}>
                <CloseOutline onClick={() => handleClickDeleteDetailData(record)} fontSize={'15px'} style={{color: '#cf1322'}} />
            </Space>
        }
    ];

    const editDelivery = async () => {
        const data = {
            id,
            customerId: form.customerId,
            invoiceTime: dayjs(form.invoiceTime).format(),
            items: detailData,
            totalRound: form.totalRound
        };
        detailData.forEach(item => {
            item.unitPrice -= 0;
            item.number -= 0;
        });
        const res = await reqUpdateDeliveryNote(data);
        if (res.code !== 200) return;
        Toast.show({
            content: '编辑成功'
        });
        navigator(-1);
    }

    const handleSetCode = async (barCode) => {
        const res = await reqGetGoods({barCode});
        if (res.code !== 200) return;
        if (res.data.length === 0) {
            Toast.show({
                content: `条形码为${barCode} 的商品不在库中!`
            });
            return;
        }

        Toast.show({
            content: barCode
        });
        setScanVisible(false);
        handleClickAddDetailData(res.data[0]);
    };

    const canInput = (good, standard) => {
        return detailData.findIndex(item => good.id === item.goodId && item.standard === standard) === -1;
    }

    const returnDialog = (item) => {
        Dialog.confirm({
            title: `选择 ${item.goodName} ${IsAdded(item.id) > -1 ? `(已添加 ${detailData[IsAdded(item.id)].number} ${detailData[IsAdded(item.id)].standard})` : ''}`,
            content: <div  style={{width: '100vw'}}>
                <Form form={addForm} layout='horizontal'>
                    <Form.Item name={'price'} label={'单价'}>
                        <VirtualInput
                            disabled={!canInput(item, addForm.getFieldValue('standard'))}
                            onClick={() => {
                                if (!canInput(item, addForm.getFieldValue('standard'))) {
                                    Toast.show({
                                        content: '同规格商品已存在 无法修改价格'
                                    });
                                }
                            }}
                            onChange={(value) => {
                                try {
                                    if (value.endsWith('-')) {
                                        value = value.substring(0, value.length - 1);
                                        value = -(value - 0) + '';
                                    }
                                    if (!value.includes('.')) {
                                        value = (value - 0).toString();
                                    }
                                    if (value.endsWith('.') && value.substring(0, value.length - 1).toString().includes('.') === true) {
                                        value = value.substring(0, value.length - 1);
                                    }
                                    if (value.startsWith('.')) {
                                        value = '0.';
                                    }
                                    addForm.setFieldValue('price', value);
                                } catch {

                                }
                            }}
                            keyboard={<NumberKeyboardWithContainer customKey={['.', '-']} />}
                        />
                    </Form.Item>
                    <Form.Item name={'number'} label={'数量'}>
                        <VirtualInput
                            onChange={(value) => {
                                try {
                                    if (!value.includes('.')) {
                                        value = (value - 0).toString();
                                    }
                                    if (value.endsWith('.') && value.substring(0, value.length - 1).toString().includes('.') === true) {
                                        value = value.substring(0, value.length - 1);
                                    }
                                    if (value.startsWith('.')) {
                                        value = '0.';
                                    }
                                    addForm.setFieldValue('number', value);
                                } catch {

                                }
                            }}
                            keyboard={<NumberKeyboardWithContainer  />}
                        />
                    </Form.Item>
                    <Form.Item name={'standard'} label={'规格'}>
                        <Input />
                    </Form.Item>
                    <Space block style={{padding: '5px'}}>
                        <Button onClick={() => {
                            addForm.setFieldValue('standard', '包');
                            addForm.setFieldValue('price',   returnGoodPrice(item, '包')+'');
                        }} size={'small'} fill={'outline'} color={'primary'}>
                            包
                        </Button>
                        <Button onClick={() => {
                            addForm.setFieldValue('standard', '件');
                            addForm.setFieldValue('price',   returnGoodPrice(item, '件')+'');
                        }} size={'small'} fill={'outline'} color={'primary'}>
                            件
                        </Button>
                        <Button onClick={() => {
                            addForm.setFieldValue('standard', '斤');
                            addForm.setFieldValue('price',   returnGoodPrice(item, '斤')+'');
                        }} size={'small'} fill={'outline'} color={'primary'}>
                            斤
                        </Button>
                    </Space>
                    <Space block>
                        <Button
                            disabled={!canInput(item, addForm.getFieldValue('standard'))}
                            onClick={async () => {
                            const req = await reqGetGoodRecentPrice({
                                customerId: form.customerId ??= 0,
                                goodId: item.id,
                                standard: addForm.getFieldValue('standard')
                            });
                            if (req.code !== 200) return;
                            if (req.data && req.data !== 0) {
                                Toast.show({
                                    content: '查询成功'
                                });
                                addForm.setFieldValue('price', req.data + '');
                                return;
                            }
                            Toast.show({
                                content: '暂无该记录'
                            });
                        }} size={'small'} color={'success'} fill={'outline'} block>
                            {`查询该客户商品价格`}
                        </Button>
                    </Space>
                </Form>
            </div>,
            onClose: () => {
                addForm.resetFields();
            },
            destroyOnClose: true,
            onConfirm: () =>  handleClickAddDetailData(item, addForm.getFieldsValue().standard, addForm.getFieldsValue().number - 0, addForm.getFieldsValue().price - 0)
        });
    }

    let timeout1;
    let timeout2;
    const IsAdded = (goodId) => {
        const index = detailData.findIndex(item => item.goodId === goodId);
        return index;
    }

    const NumberKeyboardWithContainer = (
        props,
    ) => {
        return <NumberKeyboard customKey={['.']} confirmText={'确定'} {...props} getContainer={props.container} />;
    };
    const containerStyle = {
        display: 'flex',
        height: '80vh',
    };
    const columnStyle = {
        flex: 1,
        overflowY: 'auto',
        padding: '5px',
    };
    return (
        changeWidth ? <div style={containerStyle}>
            <div style={columnStyle}>
                <Button block onClick={() => {
                    Dialog.confirm({
                        title: '快速新建商品',
                        confirmText: '创建',
                        afterClose: () => {
                            addGoodForm.resetFields();
                        },
                        onConfirm: async () => {
                            const data = addGoodForm.getFieldsValue();
                            const res = await reqAddGood(data);
                            if (res.code !== 200) return;
                            Toast.show({
                                icon: 'success',
                                content: '创建成功',
                                position: 'center',
                            });

                        },
                        content: <div
                        >
                            <Form requiredMarkStyle='asterisk' form={addGoodForm} layout='horizontal' mode='card'>
                                <Form.Item rules={[{ required: true }]} name={'goodName'} label='商品名称'>
                                    <Input placeholder='请输入商品名称' />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]} name={'unitPrice'} label='单价'>
                                    <Input type={'number'} placeholder='请输入单价' />
                                </Form.Item>
                                <Form.Item name={'wholePrice'} label='整件价'>
                                    <Input type={'number'} placeholder='请输入整件价' />
                                </Form.Item>
                                <Form.Item name={'kgPrice'} label='每斤价'>
                                    <Input type={'number'} placeholder='请输入每斤价' />
                                </Form.Item>
                                <Form.Item name={'location'} label='存放位置'>
                                    <TextArea placeholder='请输入存放位置' />
                                </Form.Item>
                            </Form>
                        </div>
                    });
                }} color={'primary'} size={'small'}>
                    创建新商品
                </Button>
                <div style={{padding: '10px 10px'}}>
                    <SearchBar
                        placeholder='输入查询商品名称'
                        onChange={(value) => {
                            let query = {};
                            if (value) {
                                query = {goodName: value, simpleMode: 1};
                            }
                            clearTimeout(timeout2);
                            // 设置一个延迟，比如500毫秒
                            timeout2 = setTimeout(async () => {
                                await getGoods(query);
                            }, 200);
                        }}
                    />
                </div>
                <div style={{height: '68vh', overflowY: 'scroll'}} >
                    <CheckList
                        className={styles.myCheckList}
                    >
                        {
                            goods.length !== 0 ? goods.map(item => (
                                <CheckList.Item readOnly={true}  key={item.id} value={item}>
                                    <Space style={{width: '100%'}} align={'center'} justify={'between'}>
                                        <div style={IsAdded(item.id) > -1 ? {color: '#4096ff', fontWeight: 'bold'} : {color: 'black'}}>
                                            {item.goodName} {IsAdded(item.id) > -1 ? `(已添加 ${detailData[IsAdded(item.id)].number} ${detailData[IsAdded(item.id)].standard})` : ''}
                                        </div>
                                        <Space>
                                            <Button onClick={() => {
                                                setGoodVisible(false);
                                                addForm.setFieldValue('number', '1');
                                                addForm.setFieldValue('standard', '包');
                                                addForm.setFieldValue('price',   returnGoodPrice(item, '包')+'');
                                                returnDialog(item);
                                            }}>
                                                按包
                                            </Button>
                                            <Button onClick={() => {
                                                setGoodVisible(false);
                                                addForm.setFieldValue('number', '1');
                                                addForm.setFieldValue('standard', '件');
                                                addForm.setFieldValue('price',   returnGoodPrice(item, '件')+'');
                                                returnDialog(item);
                                            }}>
                                                按件
                                            </Button>
                                        </Space>
                                    </Space>
                                </CheckList.Item>
                            )) : <ErrorBlock status='empty' />
                        }
                    </CheckList>
                </div>
            </div>
            <div style={columnStyle}>
                <Form  layout='horizontal' style={{'--prefix-width': '4.5em'}}>
                    <Form.Item label='订单日期'  name=''>
                        <Space align='center' >
                            <Button size={'small'} onClick={() => {
                                setDateVisible(true);
                            }} >
                                {form.invoiceTime.format('YYYY-MM-DD')}
                            </Button>
                            <DatePicker
                                visible={dateVisible}
                                onConfirm={(value) => {
                                    setForm({...form, invoiceTime: dayjs(value)});
                                }}
                                onClose={() => {
                                    setDateVisible(false)
                                }}
                            />
                            <Space align='center' >
                                <Button onClick={() => {
                                    const date = form.invoiceTime;
                                    setForm({...form, invoiceTime: dayjs(date).add(-1, 'day')});
                                }}  size={'small'} >
                                    <LeftOutline />
                                </Button>
                                <Button onClick={() => {
                                    setForm({...form, invoiceTime: dayjs()});
                                }} size={'small'}  color={'primary'}>
                                    今日
                                </Button>
                                <Button onClick={() => {
                                    const date = form.invoiceTime;
                                    setForm({...form, invoiceTime: dayjs(date).add(1, 'day')});
                                }}  size={'small'} >
                                    <RightOutline />
                                </Button>
                            </Space>
                        </Space>
                    </Form.Item>
                    <Form.Item label='客户'>
                        <div onClick={async () => {
                            await getCustomers();
                            setCustomerVisible(true);
                        }}>
                            <Input value={form.customerName}
                                   clearable
                                   readOnly
                                   onlyShowClearWhenFocus={false}
                                   onClear={() => {
                                       setForm({...form, customerId: null, customerName: ''});
                                   }}
                                   placeholder={'请选择 未选择默认为散客'}
                            />
                        </div>
                    </Form.Item>
                    <Space align={'center'}wrap={true}>
                        <Space style={{paddingLeft: '10px'}} align={'center'}>
                            <Space>
                                合计:
                            </Space>
                            <Space style={{fontWeight: 'bold', borderRadius: '15%'}} align={'center'}>
                                <Button size={'small'} className={'totalInput'}>
                                    ￥{FormatMoney(sumDetailData().sum)}
                                </Button>
                            </Space>
                        </Space>
                        <Checkbox onClick={() => {
                            let datas = [...detailData];
                            if (form.totalRound === 1) {
                                datas.forEach(item => {
                                    item.subTotal = (item.number - 0) * (item.unitPrice - 0);
                                    item.subTotal??= 0;
                                });
                                setDetailData(datas);
                                setForm({...form, totalRound: 0 });
                                return;
                            }
                            datas.forEach(item => {
                                item.subTotal = Math.round((item.number - 0) * (item.unitPrice - 0));
                                item.subTotal??= 0;
                            });
                            setDetailData(datas);
                            setForm({...form, totalRound: 1 });
                        }} checked={form.totalRound === 1}>
                            四舍五入
                        </Checkbox>
                        <Space style={{paddingRight: '10px'}} >
                            <Button onClick={async () => {
                                const result = await Modal.confirm({
                                    content: '是否清空已开单商品',
                                    confirmText: '清空',
                                });
                                if (result) {
                                    setDetailData([]);
                                }
                            }} size={'small'} color={'danger'}>
                                清空
                            </Button>

                        </Space>
                    </Space>
                    <Table columns={columns}
                           style={{paddingTop: '5px'}}
                           pagination={false}
                           emptyText={'暂无数据'}
                           data={detailData} />
                    <Popup
                        visible={customerVisible}
                        onMaskClick={() => {
                            setCustomerVisible(false);
                        }}
                        destroyOnClose
                    >
                        <div style={{padding: '10px 10px'}}>
                            <SearchBar
                                placeholder='输入文字过滤选项'
                                onChange={(value) => {
                                    let query = {};
                                    if (value) {
                                        query = {customerName: value};
                                    }
                                    clearTimeout(timeout1);
                                    // 设置一个延迟，比如500毫秒
                                    timeout1 = setTimeout(async () => {
                                        await getCustomers(query);
                                    }, 200);
                                }}
                            />
                        </div>
                        <div style={{height: '50vh', overflowY: 'scroll'}}>
                            <CheckList
                                value={[form.customerId]}
                                className={styles.myCheckList}
                                onChange={val => {
                                    if (val.length === 0) return;
                                    const index = customers.findIndex(item => item.id === val[0]);
                                    if (index === -1) {
                                        return;
                                    }
                                    setForm({...form, customerId: val[0], customerName: customers[index].customerName});
                                    setCustomerVisible(false);
                                }}
                            >
                                {
                                    customers.length !== 0 ? customers.map(item => (
                                        <CheckList.Item key={item.id} value={item.id}>
                                            {item.customerName}
                                        </CheckList.Item>
                                    )): <ErrorBlock status='empty' />
                                }
                            </CheckList>
                        </div>
                    </Popup>
                    <Popup
                        visible={goodVisible}
                        onMaskClick={() => {
                            setGoodVisible(false);
                        }}
                        destroyOnClose
                    >
                        <div style={{padding: '10px 10px'}}>
                            <SearchBar
                                placeholder='输入查询商品名称'
                                onChange={(value) => {
                                    let query = {};
                                    if (value) {
                                        query = {goodName: value};
                                    }
                                    clearTimeout(timeout2);
                                    // 设置一个延迟，比如500毫秒
                                    timeout2 = setTimeout(async () => {
                                        await getGoods(query);
                                    }, 200);
                                }}
                            />
                        </div>
                        <div style={{height: '50vh', overflowY: 'scroll'}} >
                            <CheckList
                                className={styles.myCheckList}
                            >
                                {
                                    goods.length !== 0 ? goods.map(item => (
                                        <CheckList.Item readOnly={true}  key={item.id} value={item}>
                                            <Space style={{width: '100%'}} align={'center'} justify={'between'}>
                                                <div style={IsAdded(item.id) > -1 ? {color: '#4096ff', fontWeight: 'bold'} : {color: 'black'}}>
                                                    {item.goodName} {IsAdded(item.id) > -1 ? `(已添加 ${detailData[IsAdded(item.id)].number} ${detailData[IsAdded(item.id)].standard})` : ''}
                                                </div>
                                                <Space>
                                                    <Button onClick={() => {
                                                        setGoodVisible(false);
                                                        addForm.setFieldValue('number', '1');
                                                        addForm.setFieldValue('standard', '包');
                                                        addForm.setFieldValue('price',   returnGoodPrice(item, '包')+'');
                                                        returnDialog(item);
                                                    }}>
                                                        按包
                                                    </Button>
                                                    <Button onClick={() => {
                                                        setGoodVisible(false);
                                                        addForm.setFieldValue('number', '1');
                                                        addForm.setFieldValue('standard', '件');
                                                        addForm.setFieldValue('price',   returnGoodPrice(item, '件')+'');
                                                        returnDialog(item);
                                                    }}>
                                                        按件
                                                    </Button>
                                                </Space>
                                            </Space>
                                        </CheckList.Item>
                                    )) : <ErrorBlock status='empty' />
                                }
                            </CheckList>
                        </div>
                    </Popup>
                    <CenterPopup style={{'--min-width': '100vw'}}
                                 destroyOnClose={true}
                                 onMaskClick={() => {
                                     setScanVisible(false);
                                 }}
                                 visible={scanVisible}>
                        <NavBar back='返回' onBack={() => {
                            setScanVisible(false);
                        }} backArrow={true}>
                            扫描条形码
                        </NavBar>
                        <Scan setCode={handleSetCode}/>
                    </CenterPopup>
                </Form>
                <div style={{position:'fixed', width: '50%',bottom: '0', backgroundColor: 'white'}}>
                    <Button style={{height: '45px'}} block onClick={async () => {
                        if (detailData.length === 0) {
                            Toast.show({
                                icon: 'fail',
                                content: '账单为空',
                            });
                            return;
                        }
                        Dialog.show({
                            content: '确认编辑该订单',
                            closeOnAction: true,
                            actions: [
                                [
                                    {
                                        key: 'cancel',
                                        text: '取消',
                                    },
                                    {
                                        key: 'delete',
                                        text: '确认编辑',
                                        bold: true,
                                        onClick: editDelivery
                                    },
                                ],
                            ],
                        });
                    }}  size={'small'} color={'success'}>
                        确认修改
                    </Button>
                </div>
            </div>
        </div> : <div>
            <Form layout='horizontal' style={{'--prefix-width': '4.5em'}}>
                <Form.Item label='订单日期'  name=''>
                    <Space align='center' >
                        <Button size={'small'} onClick={() => {
                            setDateVisible(true);
                        }} >
                            {form.invoiceTime.format('YYYY-MM-DD')}
                        </Button>
                        <DatePicker
                            visible={dateVisible}
                            onConfirm={(value) => {
                                setForm({...form, invoiceTime: dayjs(value)});
                            }}
                            onClose={() => {
                                setDateVisible(false)
                            }}
                        />
                        <Space align='center' >
                            <Button onClick={() => {
                                const date = form.invoiceTime;
                                setForm({...form, invoiceTime: dayjs(date).add(-1, 'day')});
                            }}  size={'small'} >
                                <LeftOutline />
                            </Button>
                            <Button onClick={() => {
                                setForm({...form, invoiceTime: dayjs()});
                            }} size={'small'}  color={'primary'}>
                                今日
                            </Button>
                            <Button onClick={() => {
                                const date = form.invoiceTime;
                                setForm({...form, invoiceTime: dayjs(date).add(1, 'day')});
                            }}  size={'small'} >
                                <RightOutline />
                            </Button>
                        </Space>
                    </Space>
                </Form.Item>
                <Form.Item label='客户'>
                    <div onClick={async () => {
                        await getCustomers();
                        setCustomerVisible(true);
                    }}>
                        <Input value={form.customerName}
                               clearable
                               readOnly
                               onlyShowClearWhenFocus={false}
                               onClear={() => {
                                   setForm({...form, customerId: null, customerName: ''});
                               }}
                               placeholder={'请选择 未选择默认为散客'}
                        />
                    </div>
                </Form.Item>
                <Form.Item label={'商品选择'}>
                    <Space>
                        <Button size={'small'}
                                onClick={async () => {
                                    await getGoods();
                                    setGoodVisible(true);
                                }}>
                            选择已有商品
                        </Button>
                        <Button onClick={() => {
                            Dialog.confirm({
                                title: '快速新建商品',
                                confirmText: '创建',
                                afterClose: () => {
                                    addGoodForm.resetFields();
                                },
                                onConfirm: async () => {
                                    const data = addGoodForm.getFieldsValue();
                                    const res = await reqAddGood(data);
                                    if (res.code !== 200) return;
                                    Toast.show({
                                        icon: 'success',
                                        content: '创建成功',
                                        position: 'center',
                                    });
                                },
                                content: <div style={{width: '100vw'}}>
                                    <Form requiredMarkStyle='asterisk' form={addGoodForm} layout='horizontal' mode='card'>
                                        <Form.Item rules={[{ required: true }]} name={'goodName'} label='商品名称'>
                                            <Input placeholder='请输入商品名称' />
                                        </Form.Item>
                                        <Form.Item rules={[{ required: true }]} name={'unitPrice'} label='单价'>
                                            <Input type={'number'} placeholder='请输入单价' />
                                        </Form.Item>
                                        <Form.Item name={'wholePrice'} label='整件价'>
                                            <Input type={'number'} placeholder='请输入整件价' />
                                        </Form.Item>
                                        <Form.Item name={'kgPrice'} label='每斤价'>
                                            <Input type={'number'} placeholder='请输入每斤价' />
                                        </Form.Item>
                                        <Form.Item name={'location'} label='存放位置'>
                                            <TextArea placeholder='请输入存放位置' />
                                        </Form.Item>
                                    </Form>
                                </div>
                            });
                        }} color={'primary'} size={'small'}>
                            创建新商品
                        </Button>
                        {/*<Button onClick={() => {*/}
                        {/*   setScanVisible(true);*/}
                        {/*}}  size={'small'}>*/}
                        {/*    <ScanningOutline />*/}
                        {/*</Button>*/}
                    </Space>
                </Form.Item>
                <Space align={'center'} wrap={true}>
                    <Space style={{paddingLeft: '10px'}} align={'center'}>
                        <Space>
                            合计:
                        </Space>
                        <Space style={{fontWeight: 'bold', borderRadius: '15%'}} align={'center'}>
                            <Button size={'small'} className={'totalInput'}>
                                ￥{FormatMoney(sumDetailData().sum)}
                            </Button>
                        </Space>
                    </Space>
                    <Checkbox onClick={() => {
                        let datas = [...detailData];
                        if (form.totalRound === 1) {
                            datas.forEach(item => {
                                item.subTotal = (item.number - 0) * (item.unitPrice - 0);
                                item.subTotal??= 0;
                            });
                            setDetailData(datas);
                            setForm({...form, totalRound: 0 });
                            return;
                        }
                        datas.forEach(item => {
                            item.subTotal = Math.round((item.number - 0) * (item.unitPrice - 0));
                            item.subTotal??= 0;
                        });
                        setDetailData(datas);
                        setForm({...form, totalRound: 1 });
                    }} checked={form.totalRound === 1}>
                        四舍五入
                    </Checkbox>
                    <Space style={{paddingRight: '10px'}} >
                        <Button onClick={async () => {
                            const result = await Modal.confirm({
                                content: '是否清空已开单商品',
                                confirmText: '清空',
                            });
                            if (result) {
                                setDetailData([]);
                            }
                        }} size={'small'} color={'danger'}>
                            清空
                        </Button>

                    </Space>
                </Space>
                <Table columns={columns}
                       style={{paddingTop: '5px'}}
                       pagination={false}
                       emptyText={'暂无数据'}
                       data={detailData} />
                <Popup
                    visible={customerVisible}
                    onMaskClick={() => {
                        setCustomerVisible(false);
                    }}
                    destroyOnClose
                >
                    <div style={{padding: '10px 10px'}}>
                        <SearchBar
                            placeholder='输入文字过滤选项'
                            onChange={(value) => {
                                let query = {};
                                if (value) {
                                    query = {customerName: value};
                                }
                                clearTimeout(timeout1);
                                // 设置一个延迟，比如500毫秒
                                timeout1 = setTimeout(async () => {
                                    await getCustomers(query);
                                }, 200);
                            }}
                        />
                    </div>
                    <div style={{height: '50vh', overflowY: 'scroll'}}>
                        <CheckList
                            value={[form.customerId]}
                            className={styles.myCheckList}
                            onChange={val => {
                                if (val.length === 0) return;
                                const index = customers.findIndex(item => item.id === val[0]);
                                if (index === -1) {
                                    return;
                                }
                                setForm({...form, customerId: val[0], customerName: customers[index].customerName});
                                setCustomerVisible(false);
                            }}
                        >
                            {
                                customers.length !== 0 ? customers.map(item => (
                                    <CheckList.Item key={item.id} value={item.id}>
                                        {item.customerName}
                                    </CheckList.Item>
                                )): <ErrorBlock status='empty' />
                            }
                        </CheckList>
                    </div>
                </Popup>
                <Popup
                    visible={goodVisible}
                    onMaskClick={() => {
                        setGoodVisible(false);
                    }}
                    destroyOnClose
                >
                    <div style={{padding: '10px 10px'}}>
                        <SearchBar
                            placeholder='输入查询商品名称'
                            onChange={(value) => {
                                let query = {};
                                if (value) {
                                    query = {goodName: value};
                                }
                                clearTimeout(timeout2);
                                // 设置一个延迟，比如500毫秒
                                timeout2 = setTimeout(async () => {
                                    await getGoods(query);
                                }, 200);
                            }}
                        />
                    </div>
                    <div style={{height: '50vh', overflowY: 'scroll'}} >
                        <CheckList
                            className={styles.myCheckList}
                        >
                            {
                                goods.length !== 0 ? goods.map(item => (
                                    <CheckList.Item readOnly={true}  key={item.id} value={item}>
                                        <Space style={{width: '100%'}} align={'center'} justify={'between'}>
                                            <div style={IsAdded(item.id) > -1 ? {color: '#4096ff', fontWeight: 'bold'} : {color: 'black'}}>
                                                {item.goodName} {IsAdded(item.id) > -1 ? `(已添加 ${detailData[IsAdded(item.id)].number} ${detailData[IsAdded(item.id)].standard})` : ''}
                                            </div>
                                            <Space>
                                                <Button onClick={() => {
                                                    setGoodVisible(false);
                                                    addForm.setFieldValue('number', '1');
                                                    addForm.setFieldValue('standard', '包');
                                                    addForm.setFieldValue('price',   returnGoodPrice(item, '包')+'');
                                                    returnDialog(item);
                                                }}>
                                                    按包
                                                </Button>
                                                <Button onClick={() => {
                                                    setGoodVisible(false);
                                                    addForm.setFieldValue('number', '1');
                                                    addForm.setFieldValue('standard', '件');
                                                    addForm.setFieldValue('price',   returnGoodPrice(item, '件')+'');
                                                    returnDialog(item);
                                                }}>
                                                    按件
                                                </Button>
                                            </Space>
                                        </Space>
                                    </CheckList.Item>
                                )) : <ErrorBlock status='empty' />
                            }
                        </CheckList>
                    </div>
                </Popup>
                <CenterPopup style={{'--min-width': '100vw'}}
                             destroyOnClose={true}
                             onMaskClick={() => {
                                 setScanVisible(false);
                             }}
                             visible={scanVisible}>
                    <NavBar back='返回' onBack={() => {
                        setScanVisible(false);
                    }} backArrow={true}>
                        扫描条形码
                    </NavBar>
                    <Scan setCode={handleSetCode}/>
                </CenterPopup>
            </Form>
            <div style={{position:'fixed', width: '100%',bottom: '0', zIndex: 99,backgroundColor: 'white'}}>
                <Button style={{height: '45px'}} block onClick={async () => {
                    if (detailData.length === 0) {
                        Toast.show({
                            icon: 'fail',
                            content: '账单为空',
                        });
                        return;
                    }
                    Dialog.show({
                        content: '确认编辑该订单',
                        closeOnAction: true,
                        actions: [
                            [
                                {
                                    key: 'cancel',
                                    text: '取消',
                                },
                                {
                                    key: 'delete',
                                    text: '确认编辑',
                                    bold: true,
                                    onClick: editDelivery
                                },
                            ],
                        ],
                    });
                }}  size={'small'} color={'success'}>
                    确认修改
                </Button>
            </div>
        </div>
    );
};

export default EditDeliveryNote;
