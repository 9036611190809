import api from './api';
import axios from 'axios';

// 查看商品信息
export const reqGetGoods = (query = {}) =>
    api.get('/goods', {
        params: query
    });

export const reqDeleteGoods = (id) =>
    api.delete('/good/' + id);

export const reqAddGood = (data) =>
    api.post('/good', data);

export const reqUpdateGood = (data) =>
    api.put('/good', data);

export const reqGetGoodRecentPrice = (query) =>
    api.get('/good/recentprice', {
        params: query
    })

// 导出价格表
export const reqExportGoodPrices = () =>
    api.get('/goods/export', {responseType: 'blob'});

export const reqUploadPhoto = (formdata) =>
    api.post('/good/photo', formdata, {headers: {'Content-Type': 'multipart/form-data'}})
