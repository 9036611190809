import React, {useEffect, useState} from 'react';
import Table from 'rc-table';
import {
    reqAddCustomerRelation,
    reqDeleteCustomers,
    reqGetCustomerGuid,
    reqGetCustomers,
    reqGetCustomersBill, reqUpdateCustomerGuid
} from '../../api/customer';
import {
    ActionSheet,
    Button,
    CheckList,
    Dialog,
    Divider,
    ErrorBlock, Form, Input,
    Modal,
    Popup,
    SearchBar,
    Space,
    Toast
} from 'antd-mobile';
import {FillinOutline} from 'antd-mobile-icons';
import dayjs from 'dayjs';
import {FormatMoney} from '../../utils/formatMoney';
import {useLocation, useNavigate} from 'react-router';
import styles from '../deliverynotes/addDeliveryNote.css';

const Customer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [form1] = Form.useForm();
    const [customers, setCustomers] = useState([]);
    const [editSelect, setEditSelect] = useState(null);
    const [query, setQuery] = useState({
        // startTime: dayjs().startOf('month'),
        // endTime: dayjs().endOf('month'),
        customerIds: null
    });
    const [relationCheckList, setRelationCheckList] = useState([]);
    const [relationCustomer, setRelationCustomer] = useState([]);
    const [relationVisible, setRelationVisible] = useState(null);
    const getCustomers = async (query = {}) => {
        const res = await reqGetCustomersBill(query);
        if (res.code !== 200) return;
        let key = 0;
        res.data.forEach(item => {
            item['key'] = key++;
        });

        setCustomers(res.data);
    }
    // 获取子账务
    const getRelationCustomers = async (query ={}) => {
        const res = await reqGetCustomers(query);
        if (res.code !== 200) return;
        let key = 0;
        let selectList = [];
        res.data.forEach(item => {
            item['key'] = key++;
            if (item.childrenCustomer) {
                selectList.push(item.id);
            }
        });

        setRelationCheckList(selectList);
        setRelationCustomer(res.data);
    }

    useEffect(() => {
        getCustomers();
    }, []);
    let timeout2;

    return (
        <div>
            <SearchBar style={{padding: '5px 5px'}}
                       onClear={async () => {
                           await getCustomers();
                       }}
                       onChange={(value) => {
                           let query = {};
                           if (value) {
                               query = {customerName: value};
                           }
                           clearTimeout(timeout2);
                           // 设置一个延迟，比如500毫秒
                           timeout2 = setTimeout(async () => {
                               await getCustomers(query);
                           }, 200);
                       }}
                       placeholder='客户名称'
                       showCancelButton={() => false} />
            <Table
                columns={[
                    {
                        title: customers === 0 ? `客户` : `客户(${customers.length})`,
                        dataIndex: 'customerName',
                    },
                    {
                        title: '欠款',
                        dataIndex: 'arrears',
                        width: 90,
                        render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                            {FormatMoney(value)}
                        </div>
                    },
                    {
                        title: '关联客户',
                        dataIndex: 'childrenNumber',
                    },
                    {
                        title: '单数',
                        width: 50,
                        dataIndex: 'deliveryNumber',
                        render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                            {value}
                        </div>
                    },
                    {
                        title: '未结',
                        width: 50,
                        dataIndex: 'unConfirmNumber',
                        render: (value) => <div style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                            {value}
                        </div>
                    },
                    {
                        title: ' ',
                        width: 20,
                        render: (_, record) => <FillinOutline color={'#237804'} onClick={() => {
                            setEditSelect(record);
                        }} fontSize={'20px'}/>
                    }
                ]}
                emptyText={'暂无数据'}
                data={customers}
            />

            <ActionSheet
                cancelText='取消'
                visible={editSelect}
                actions={[
                    {
                        text: '编辑账务关系',
                        onClick: async () => {
                            const data = editSelect;
                            setEditSelect(null);
                            setRelationVisible(data);
                            query['relationCustomerId'] = data.id;
                            await getRelationCustomers(query);
                        },
                        key: 0
                    },
                    {
                        bold: true,
                        text: '对账单',
                        onClick: () => {
                            const data = editSelect;
                            setEditSelect(null);
                            navigate('/statement/' + data.id);
                        },
                        key: 1
                    },
                    {
                        text: '下单助手',
                        onClick: async () => {
                            const data = editSelect;
                            setEditSelect(null);
                            const res = await reqGetCustomerGuid(data.id);
                            if (res.code !== 200) return;
                            let guid = res.data;
                            let prefix = window.location.href.replace("/customer", "/order");
                            form1.setFieldValue('path', `${prefix}/${guid}`);

                            Dialog.alert({
                                content: <div  style={{width: '100vw'}}>
                                    <Form form={form1}>
                                        <Form.Item name={'path'} label={'下单网址'}>
                                            <Input value={guid} readOnly/>
                                        </Form.Item>
                                        <Space>
                                            <Button onClick={ async () => {
                                                const res = await reqUpdateCustomerGuid(data.id);
                                                if (res.code !== 200) return;
                                                form1.setFieldValue('path', `${prefix}/${res.data}`);
                                            }} size={'small'} color={'primary'}>
                                                重新生成
                                            </Button>

                                            <Button onClick={() => {
                                                navigator.clipboard.writeText(form1.getFieldValue('path'))
                                                    .then(() => {
                                                        Toast.show({content: '复制成功'});
                                                    }).catch(() => {
                                                        Toast.show({content: '复制失败'});
                                                });
                                            }} size={'small'} color={'success'}>
                                                复制
                                            </Button>
                                        </Space>
                                    </Form>

                                </div>
                            });
                        },
                        key: 8,
                    },
                    {
                        text: '编辑客户信息',
                        onClick: () => {
                            const data = editSelect;
                            setEditSelect(null);
                            navigate('/editcustomer/' + data.id);
                        },
                        key: 2
                    },
                    {
                        text: '删除客户信息',
                        danger: true,
                        bold: true,
                        onClick: () => {
                            const data = editSelect;
                            setEditSelect(null);
                            Dialog.show({
                                content: '是否删除该客户信息',
                                closeOnAction: true,
                                actions: [
                                    [
                                        {
                                            key: 'cancel',
                                            text: '取消',
                                        },
                                        {
                                            key: 'delete',
                                            text: '删除',
                                            bold: true,
                                            danger: true,
                                            onClick: async () => {
                                                const res = await reqDeleteCustomers(data.id);
                                                if (res.code !== 200) return;
                                                Toast.show({
                                                    content: '删除成功',
                                                });
                                                await getCustomers();
                                            }
                                        },
                                    ],
                                ],
                            });
                        }
                    }
                ]}
                onClose={() => setEditSelect(null)}
            />
            <Popup
                visible={relationVisible}
                onMaskClick={() => {
                    setRelationVisible(null);
                }}
                destroyOnClose>
                <Space align={'center'} style={{padding: '5px 5px', width: '100%'}}>
                    <SearchBar style={{padding: '5px 5px', flex: 1}}
                               onChange={(value) => {
                                   let query = {};
                                   if (value) {
                                       query = {customerName: value};
                                   }
                                   query['relationCustomerId'] = relationVisible.id;
                                   clearTimeout(timeout2);
                                   // 设置一个延迟，比如500毫秒
                                   timeout2 = setTimeout(async () => {
                                       await getRelationCustomers(query);
                                   }, 200);
                               }}
                               showCancelButton={() => true}
                               placeholder='关联客户名称'
                               onCancel={() => {
                                   setRelationVisible(null);
                               }}/>
                    <Button onClick={async () => {
                        const data = {customerId:relationVisible.id, childrenCustomerIds: []};
                        for (let i = 0; i < relationCheckList.length; i++) {
                            data.childrenCustomerIds.push(relationCheckList[i]);
                        }
                        const res = await reqAddCustomerRelation(data);
                        if (res.code !== 200) return;
                        Toast.show({
                            content: '保存成功',
                            icon: 'success'
                        });
                        getCustomers();
                        setRelationVisible(null);
                        setRelationCustomer([]);
                    }} size={'small'} color={'primary'}>
                        保存
                    </Button>
                </Space>
                <div style={{height: '50vh', overflowY: 'scroll'}} >
                    <CheckList
                        value={relationCheckList}
                        onChange={(value) => {
                            setRelationCheckList(value);
                        }}
                        multiple
                        className={styles.myCheckList}
                    >
                        {
                            relationCustomer.length !== 0 ? relationCustomer.map(item =>
                                <CheckList.Item key={item.id} value={item.id}>
                                    {
                                        item.customerName
                                    }
                                </CheckList.Item>) : <ErrorBlock status='empty' />
                        }
                    </CheckList>
                </div>
            </Popup>
        </div>
    );
};

export default Customer;
