import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {
    Button,
    ErrorBlock,
    Form, Grid,
    Image,
    ImageViewer,
    Input,
    List,
    NavBar,
    Space,
    Stepper,
    Steps,
    TextArea,
    Toast
} from 'antd-mobile';
import {reqvalidateCustomerGuid} from '../../api/customer';
import {reqGetOrderDetail, reqGetOrderGoodPhotos} from '../../api/order';
import {Step} from 'antd-mobile/es/components/steps/step';
import dayjs from 'dayjs';
import {ListItem} from 'antd-mobile/es/components/list/list-item';
import {baseURL} from '../../api/api';
import html2canvas from 'html2canvas';

// 订单明细
const OrderDetail = () => {
    const {id, bookid} = useParams();
    const navigator = useNavigate();
    const [customerInfo, setCustomerInfo] = useState({});

    const [form] = Form.useForm();

    const [detail, setDetail] = useState(null);
    const [imgVisible, setImgVisible] = useState(false);
    const [detailImg, setDetailImg] = useState(null);

    const [items, setItems] = useState([]);

    useEffect(() => {
        document.title = '嘉伟商行预订';
        validateCustomer();
    }, [id, bookid]);

    const validateCustomer = async () => {
        const res = await reqvalidateCustomerGuid(id);
        if (res.code !== 200) {
            Toast.show({
                content: '无法访问该页面 请联系嘉伟商行工作人员'
            });
            setCustomerInfo(null);
            return;
        }
        await getDetail();
        setCustomerInfo(res.data);
    }

    const getDetail = async () => {
        const res = await reqGetOrderDetail(bookid, id);
        if (res.code !== 200) {
            navigator(`/orderlist/${id}`);
            return;
        }
        setDetail(res.data);
        setItems(res.data.items);
        form.setFieldValue('createTime', dayjs(res.data.createTime).format('YYYY年MM月DD日 HH:mm'));
        form.setFieldValue('remark', res.data.remark);
    }

    const generateStatus = () => {
        if (!detail) return;

        switch (detail.status) {
            case 0:
                return <Steps current={1}>
                    <Step title='已下单' />
                    <Step title='商家处理中' />
                    <Step title='已完成' />
                </Steps>;
            case 1:
                return <Steps current={2}>
                    <Step title='已下单' />
                    <Step title='商家处理中' />
                    <Step title='已完成' />
                </Steps>;
            case -1:
                return <Steps  current={2}>
                    <Step title='已下单' />
                    <Step title='商家处理中' />
                    <Step status={'error'} title='订单关闭' />
                </Steps>;
            default:
                return;
        }
    }

    const save2Img = () => {
        setImgVisible(true);
        const element = document.getElementById('bookDetail'); // 替换为你要保存为图片的元素的ID
        html2canvas(element)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                setDetailImg(imgData);
            });
    }

    const getShopListSize = () => {
        if (items.size === 0) return 0;
        let sum = 0;
        items.forEach((item) => {
            if (item.standard === '斤') {
                sum += 1;
                return;
            }
            sum += item.number;
        });
        return sum;
    }
    return (
        customerInfo ?
         <div>
            <NavBar style={{position:'fixed', padding: 0, width: '100%', backgroundColor: '#f0f0f0', zIndex: 100, top: 0}}
                    onBack={() => {
                        navigator(`/orderlist/${id}`);
                    }}><div style={{fontWeight: 'bold'}}>
                订单详情
            </div></NavBar>
            <div style={{paddingTop: '50px'}}>
                {
                    generateStatus()
                }
                <div id={'bookDetail'}>
                    <Form layout='horizontal' form={form}>
                        <Form.Item hidden={!imgVisible}label={'客户名称'}>
                            <Input value={customerInfo.customerName} readOnly />
                        </Form.Item>
                        <Form.Item name={'createTime'} label={'下单时间'}>
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item name={'remark'} label={'备注'}>
                            <TextArea placeholder={'暂无备注'} readOnly />
                        </Form.Item>
                    </Form>
                    <List>
                        {
                            items.length === 0 ? <ErrorBlock status={'empty'} /> : items.map(good =>
                                <ListItem key={good.goodName}
                                          prefix={
                                              <Image
                                                  src={good.mainPhoto ? baseURL + '/good/photo/path/' + good.mainPhoto : null}
                                                  style={{ borderRadius: 4 }}
                                                  fit='cover'
                                                  onClick={async () => {
                                                      const res = await reqGetOrderGoodPhotos(good.goodId, id);
                                                      if (good.mainPhoto && res.code === 200) {
                                                          ImageViewer.Multi.show({ images: res.data.map(item => {
                                                                  return baseURL + '/good/photo/path/' + item;
                                                              }) })
                                                      }
                                                  }}
                                                  lazy={true}
                                                  width={100}
                                                  height={100}
                                              />
                                          }
                                          extra={<Space style={{paddingTop: '70px', color: 'black', fontSize: '18px'}} align={'center'}>
                                              {good.number} {good.standard}
                                          </Space>}
                                >
                                    <div style={{height: '100px', fontWeight: 'bold'}}>
                                        {good.goodName}
                                    </div>
                                </ListItem>)
                        }
                    </List>
                </div>
                </div>
             <div style={{position:'fixed', width: '100%',bottom: '0',backgroundColor: 'white', zIndex: '99'}}>
                 <Grid style={{alignItems: 'center'}} columns={10}>
                     <Grid.Item span={6}>
                         <div style={{fontSize: '20px', paddingLeft: '20px'}}>
                             已预定{getShopListSize()}个商品
                         </div>
                     </Grid.Item>
                     <Grid.Item span={4}>
                         <Button
                                 onClick={save2Img}
                                 onTouchStart={() => setImgVisible(true)}
                                 onTouchEnd={save2Img}  size={'large'} color={'primary'} block>
                             保存为图片
                         </Button>
                     </Grid.Item>
                 </Grid>
             </div>
             <ImageViewer
                 classNames={{
                     mask: 'customize-mask',
                     body: 'customize-body',
                 }}
                 image={detailImg}
                 renderFooter={(img) => {
                     return <div className={'vfooter'}>
                         <div onClick={() => {
                             var downloadLink = document.createElement("a");
                             downloadLink.href = img;
                             // 设置下载文件的名称
                             downloadLink.download = `${dayjs().format('YYYYMMDDHHmm')}预定单.png`;

                             // 将链接添加到文档中并模拟点击
                             document.body.appendChild(downloadLink);
                             downloadLink.click();

                             // 清理
                             document.body.removeChild(downloadLink);
                         }} className={'vfooterButton'}>
                             下载图片
                         </div>
                     </div>
                 }}
                 visible={imgVisible}
                 onClose={() => {
                     setImgVisible(false);
                 }}
             />
        </div>:
        <ErrorBlock fullPage={true} status='default' />
    );
};

export default OrderDetail;
