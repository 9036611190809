import React from 'react';
import {Button, List, Toast} from 'antd-mobile';
import {useNavigate} from 'react-router';
const Me = () => {

    const navigate = useNavigate();

    return (
        <div>
            <List>
                <List.Item onClick={() => {
                    navigate('/manufacturer')
                }}>
                    供货账本
                </List.Item>
            </List>
            <Button color={'primary'} block onClick={() => {
                localStorage.removeItem('jwt');
                window.location.reload();
            }}>
                退出
            </Button>
        </div>
    );
};

export default Me;
