import api from './api';

// 获取客户信息
export const reqGetDeliveryNotes = (data = {}) =>
    api.post('/deliverynotes', data);

export const reqGetDeliveryNoteDates = (data = {}) =>
    api.post('deliverynotes/date', data);

// 添加货单信息
export const reqAddDeliveryNote = (data) =>
    api.post('/deliverynote', data)

// 删除货单信息
export const reqDeleteDeliveryNotes = (id) =>
    api.delete('/delivernote/' + id);

export const reqGetDeliveryNote = (id) =>
    api.get('/deliverynote/' + id);

export const reqUpdateDeliveryNote = (data) =>
    api.put('/deliverynote', data)

export const reqConfirmDeliveryNotes = (data) =>
    api.put('delivernote/confirm', data);

export const reqUnConfirmDeliveryNotes = (data) =>
    api.put('delivernote/unconfirm', data);

export const reqGetDeliveryNotePrint = (id, query = {}) =>
    api.get('/deliverynote/print/' + id, {
        responseType: 'blob',
        params: query
    });

export const reqGetDeliverNoteRemotePrint = (id, query = {}) =>
    api.get('/deliverynote/remoteprint/' + id, {
        params: query
    });

export const reqGetRemotePrinterStatus = () =>
    api.get('/printer/status');

export const reqGetRemotePrinterClean =() =>
    api.get('/printer/clean');

export const reqGetDeliveryNoteHtml = (id, query = {}) =>
    api.get('/deliverynote/html/' + id, {
        params: query
    });

export const reqConfirmDeliveryMoney = (data) =>
    api.put('/delivernote/money', data);
