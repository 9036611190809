import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {reqvalidateCustomerGuid} from '../../api/customer';
import {
    Button,
    Dialog,
    ErrorBlock,
    Form, Grid,
    Image,
    ImageViewer,
    Input,
    List,
    NavBar,
    Space,
    Stepper, TabBar, TextArea,
    Toast
} from 'antd-mobile';
import {baseURL} from '../../api/api';
import {reqAddOrder, reqGetOrderGoodPhotos} from '../../api/order';
import dayjs from 'dayjs';

const AddOrder = () => {

    const {id} = useParams();
    const navigator = useNavigate();
    const [addForm] = Form.useForm();
    const [customerInfo, setCustomerInfo] = useState({});
    const [shopList, setShopList] = useState(new Map());
    useEffect(() => {
        document.title = '嘉伟商行预订';
        validateCustomer();
        if (localStorage.getItem('shopList')) {
            setShopList(new Map(JSON.parse(localStorage.getItem('shopList'))));
        } else {
            Toast.show({content: '购物车没有商品哦~'});
            navigator('/order/' + id);
        }
    }, [id]);

    const returnChineseStandard = (key) => {
        const obj = {unit: '包', whole: '件', kg: '斤'};
        return obj[key];
    }

    const validateCustomer = async () => {
        const res = await reqvalidateCustomerGuid(id);
        if (res.code !== 200) {
            Toast.show({
                content: '无法访问该页面 请联系嘉伟商行工作人员'
            });
            setCustomerInfo(null);
            return;
        };
        setCustomerInfo(res.data);
    }
    const generateExtra = (good) => {
        const obj = shopList.get(good.id);
        const keys = Object.keys(obj).filter(item => item !== 'goodName' && item !== 'mainPhoto');
        if (keys.length === 1 && shopList.get(good.id)[keys[0]] !== 0) {
            return <Space style={{paddingTop: '70px'}} align={'center'}>
                <Stepper
                    min={0}
                    onChange={(value) => {
                        if (value === 0 && shopList.size === 1) {
                            Toast.show({content: '商品数量不能小于1'});
                            return;
                        }
                        const map = new Map(shopList);
                        const o = map.get(good.id);
                        o[keys[0]] = value;
                        if (value === 0) {
                            map.delete(good.id);
                            setShopList(map);
                            return;
                        }
                        map.set(good.id, o);
                        setShopList(map);
                    }}
                    value={shopList.get(good.id)[keys[0]]} />
                <div>
                    {returnChineseStandard(keys[0])}
                </div>
            </Space>;
        }
    }

    const handleAddOrder = async () => {
        if (shopList.size === 0) {
            Toast.show({content: '未选择商品'});
            return;
        }

        Toast.show({
            icon: 'loading',
            content: '正在提交预订单',
            duration: 5000
        });

        const items = [];

        shopList.forEach((value, key) => {
            let standard = '';
            let number = 0;
            if (value.unit) {
                standard = '包';
                number = value.unit;
            }
            if (value.whole) {
                standard = '件';
                number = value.whole;
            }
            if (value.kg) {
                standard = '斤';
                number = value.kg;
            }
            items.push({
                goodId: key,
                number: number,
                standard
            });
        });

        const data = {
            customerId: customerInfo.id,
            items,
            remark: addForm.getFieldValue('remark')
        };
        const res = await reqAddOrder(data, id);

        if (res.code !== 200) return;

        Toast.show({
            icon: 'success',
            content: '创建成功',
            duration: 1000
        });
        navigator('/orderlist/' + id);
        localStorage.removeItem('shopList');
    }

    // 获取数量
    const getShopListSize = () => {
        if (shopList.size === 0) return 0;
        let sum = 0;
        shopList.forEach((value, key) => {
            if (value.whole) {
                sum += value.whole;
                return;
            }
            if (value.kg) {
                sum += 1;
                return;
            }
            if (value.unit) {
                sum += value.unit;
            }
        });
        return sum;
    }

    const generateShopList = () => {
        if (shopList.size === 0) {
            return <ErrorBlock
                style={{padding: '50px'}}
                status={'empty'}
                title={'购物车还是空的哦~'}
                description={''}
            />;
        }
        const list = [];
        shopList.forEach((value, key) => {
            list.push(<List.Item
                key={value.goodName}
                prefix={
                    <Image
                        src={value.mainPhoto ? baseURL + '/good/photo/path/' + value.mainPhoto : null}
                        style={{ borderRadius: 4 }}
                        fit='cover'
                        onClick={async () => {
                            const res = await reqGetOrderGoodPhotos(key, id);
                            if (value.mainPhoto && res.code === 200) {
                                ImageViewer.Multi.show({ images: res.data.map(item => {
                                        return baseURL + '/good/photo/path/' + item;
                                    }) })
                            }
                        }}
                        lazy={true}
                        width={100}
                        height={100}
                    />
                }
                extra={generateExtra({
                    id: key,
                    goodName: value.goodName
                })}
            >
                <div style={{height: '100px', fontWeight: 'bold'}}>
                    {value.goodName}
                </div>
            </List.Item>);
        });
        return list;
    }

    return (
        <div>
            <NavBar style={{position:'fixed', padding: 0, width: '100%', backgroundColor: '#f0f0f0', zIndex: 100, top: 0}}
                    onBack={() => {
                        Dialog.confirm({
                            content: '确认要放弃预定吗?',
                            confirmText: '继续创建',
                            cancelText: '残忍离开',
                            onCancel: () => navigator('/order/' + id)
                        })
                    }}><div style={{fontWeight: 'bold'}}>
                创建预订单
            </div></NavBar>
            <div style={{paddingTop: '50px', paddingBottom: '50px'}}>
                <Form form={addForm} layout='horizontal'>
                    <Form.Item label={'备注'} name={'remark'}>
                        <TextArea placeholder={'请输入您要备注的内容'} />
                    </Form.Item>
                </Form>
                <List>
                    {
                        generateShopList()
                    }
                </List>
            </div>
            <div style={{position:'fixed', width: '100%',bottom: '0',backgroundColor: 'white', zIndex: '99'}}>
                <Grid style={{alignItems: 'center'}} columns={10}>
                <Grid.Item span={6}>
                    <div style={{fontSize: '20px', paddingLeft: '20px'}}>
                        已选择{getShopListSize()}个商品
                    </div>
                </Grid.Item>
                <Grid.Item span={4}>
                    <Button onClick={handleAddOrder} size={'large'} color={'primary'} block>
                        确认创建
                    </Button>
                </Grid.Item>
            </Grid>
            </div>
        </div>
    );
};

export default AddOrder;
