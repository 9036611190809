import api from './api';

// 获取客户信息
export const reqGetOrderGoods = (query = {}, token = '') =>
    api.get('/customerbook/goods', {
        params: query,
        headers: {
            Token: token
        }
    });

export const reqGetOrderGoodPhotos = (goodId, token = '') =>
    api.get('/customerbook/goodphoto/' + goodId, {
        headers: {
            Token: token
        }
    });

export const reqAddOrder = (data, token = '') =>
    api.post('/customerbook/book', data, {
        headers: {
            Token: token
        }
    });

export const reqGetOrders = (query = {}, token = '') =>
    api.get('/customerbook/books', {
        headers: {
            Token: token
        },
        params: query
    });

export const reqGetOrderDetail = (id, token = '') =>
    api.get('/customerbook/book/' + id, {
        headers: {
            Token: token
        }
    });
