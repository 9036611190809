import React, {useEffect, useState} from 'react';
import {
    Dialog,
    Form,
    ImageViewer,
    NumberKeyboard,
    Selector,
    Space,
    TabBar,
    Tag,
    TextArea,
    Toast,
    VirtualInput
} from 'antd-mobile';
import {
    UploadOutline,
    EditSOutline,
    PictureOutline,
    CheckCircleOutline
} from 'antd-mobile-icons';
import Table from 'rc-table';
import './deliveryNoteDetail.css';
import {useNavigate, useParams} from 'react-router';
import {reqConfirmDeliveryMoney, reqGetDeliveryNote, reqGetDeliveryNoteHtml} from '../../api/deliverynote';
import {FormatMoney} from '../../utils/formatMoney';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';

const NumberKeyboardWithContainer = (
    props,
) => {
    return <NumberKeyboard customKey={['.']} confirmText={'确定'} {...props} getContainer={props.container} />;
};

// 详情
const DeliveryNoteDetail = () => {

    const {id} = useParams();
    const [form1] = Form.useForm();

    const [detailData, setDetailData] = useState([]);

    const [detailImg, setDetailImg] = useState(null);
    const [htmlData, setHtmlData] = useState(null);
    const [imgVisible, setImgVisible] = useState(false);

    const [main, setMain] = useState({
        actualTotal: null,
        invoiceTime: null,
        customerId: null,
        noteTotal: null,
        customerName: null,
        remark: null,
        confirmStatus: 0,
        totalRound: null
    });
    const navigate = useNavigate();

    useEffect(() => {
        getDetail();
    }, [])

    const getDetail = async () => {
        const res = await reqGetDeliveryNoteHtml(id);
        if (res.code !== 200) return;
        setMain({
            id: res.data.id,
            actualTotal: res.data.actualTotal,
            invoiceTime: res.data.invoiceTime,
            customerId: res.data.customerId,
            customerName: res.data.customerName,
            remark: res.data.remark,
            confirmStatus: res.data.confirmStatus,
            noteTotal: res.data.noteTotal,
            totalRound: res.data.totalRound
        });
        setHtmlData(res.html);
        setDetailData(res.data.items);
    }

    const save2Img = () => {
        setImgVisible(true);
        const element = document.getElementById('deliveryDetail'); // 替换为你要保存为图片的元素的ID
        html2canvas(element)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            setDetailImg(imgData);
        });
    }

    const tabs = [
        {
            key: 'photo',
            title: '图片',
            icon: <PictureOutline onClick={save2Img} />
        },
        {
            key: 'print',
            title: '打印',
            icon: <UploadOutline onClick={() => {
                navigate(`/print/${id}`);
            }} />
        },
        {
            key: 'check',
            title: '结算',
            icon: <CheckCircleOutline onClick={async () => {
                form1.setFieldValue('confirmStatus', [main.confirmStatus]);
                form1.setFieldValue('remark', main.remark);
                if (main.actualTotal) {
                    form1.setFieldValue('actualTotal', main.actualTotal + '');
                }
                Dialog.confirm({
                    onConfirm: async () => {
                        const p = form1.getFieldsValue();
                        if (p.confirmStatus === undefined) {
                            p.confirmStatus = [0];
                        }
                        if (p.actualTotal) {
                            p.actualTotal = p.actualTotal - 0;
                        }
                        p.confirmStatus = p.confirmStatus[0];
                        p['id'] = main.id;
                        const res = await reqConfirmDeliveryMoney(p);
                        if (res.code !== 200) return;
                        Toast.show({
                            content: '更新成功',
                        });
                        await getDetail();
                    },
                    onClose: () => {
                        form1.resetFields();
                    },
                    confirmText: '保存',
                    content: <div>
                        <Form form={form1} requiredMarkStyle='asterisk' layout='horizontal' mode='card'>
                            <Form.Item  label={'账单应收金额'}>
                                <div onClick={() => {
                                    Toast.show({
                                        content: '应收为实时计算 不可修改',
                                        icon: 'fail'
                                    });
                                }}>{FormatMoney(main.noteTotal)}</div>
                            </Form.Item>
                            <Form.Item name={'actualTotal'} label={'实收金额'}>
                                <VirtualInput
                                    onChange={(value) => {
                                        try {
                                            if (!value.includes('.')) {
                                                value = (value - 0).toString();
                                            }
                                            if (value.endsWith('.') && value.substring(0, value.length - 1).toString().includes('.') === true) {
                                                value = value.substring(0, value.length - 1);
                                            }
                                            if (value.startsWith('.')) {
                                                value = '0.';
                                            }
                                            form1.setFieldValue('actualTotal', value);
                                        } catch {

                                        }
                                    }}
                                    keyboard={<NumberKeyboardWithContainer  />}
                                />
                            </Form.Item>
                            <Form.Item name={'confirmStatus'} label={'是否结算'}>
                                <Selector
                                    options={[
                                        {
                                            label: '已结算',
                                            value: 1,
                                        },
                                        {
                                            label: '未结算',
                                            value: 0,
                                        },
                                    ]}
                                    defaultValue={[0]}
                                />
                            </Form.Item>
                            <Form.Item name={'remark'} label={'备注'}>
                                <TextArea placeholder={'备注'} />
                            </Form.Item>
                        </Form>
                    </div>,
                    title: '结算账单'
                });
            }} />
        },
        {
            key: 'todo',
            title: '修改',
            icon: <EditSOutline onClick={() => {
                navigate(`/edit/${id}`);
            }} />,
        }
    ]

    return (
        <div>
            <ImageViewer
                classNames={{
                    mask: 'customize-mask',
                    body: 'customize-body',
                }}
                image={detailImg}
                renderFooter={(img) => {
                    return <div className={'vfooter'}>
                        <div onClick={() => {
                            var downloadLink = document.createElement("a");
                            downloadLink.href = img;
                            // 设置下载文件的名称
                            downloadLink.download = `出库单.png`;

                            // 将链接添加到文档中并模拟点击
                            document.body.appendChild(downloadLink);
                            downloadLink.click();

                            // 清理
                            document.body.removeChild(downloadLink);
                        }} className={'vfooterButton'}>
                            下载图片
                        </div>
                    </div>
                }}
                visible={imgVisible}
                onClose={() => {
                    setImgVisible(false);
                }}
            />
            <div >
                <Space style={{padding: '5px 20px', fontWeight: 'bold'}} justify={'between'} block>
                    <div>
                        已收:{main.actualTotal ? FormatMoney(main.actualTotal) : '未收款'} {main.totalRound === 1 ? '(已四舍五入)' : ''}
                    </div>
                    <div>
                        状态: {main.confirmStatus === 1 ? <Tag style={{fontSize: '15px'}} color='success'>已结</Tag> : <Tag style={{fontSize: '15px'}} color='danger'>未结</Tag>}
                    </div>
                </Space>
                {
                    main.remark ? <Space style={{padding: '0px 20px', fontWeight: 'bold'}} justify={'between'} block>
                        <div>
                            备注: {main.remark}
                        </div>
                    </Space> : null
                }
                <Space style={{padding: '5px 20px', fontWeight: 'bold'}} justify={'between'} block>
                    <div>
                        日期:{dayjs(main.invoiceTime).format('YYYY年MM月DD日')}
                    </div>
                    <div>
                        {main.customerId === 1 ? null : `客户名称:${main.customerName}`}
                    </div>
                </Space>
                <div style={{ position: 'relative', paddingBottom: '100px'}}>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', backgroundColor: 'white' }}>
                        <div style={{padding: '0 10px 10px 10px'}} dangerouslySetInnerHTML={{ __html: htmlData }} />
                    </div>
                    <div id={'deliveryDetail'} style={{overflow: 'hidden',zIndex: 1,width: window.innerWidth > 500 ? '500px' : `${window.innerWidth- 10}px`, padding: '0 5px 5px 5px' }} dangerouslySetInnerHTML={{ __html: htmlData }} />
                </div>
            </div>

            <TabBar style={{position:'fixed', width: '100%',bottom: '0', backgroundColor: 'white'}}>
                {tabs.map(item => (
                    <TabBar.Item
                        key={item.key}
                        icon={item.icon}
                        title={item.title}
                        badge={item.badge}
                    />
                ))}
            </TabBar>
        </div>
    );
};

export default DeliveryNoteDetail;
