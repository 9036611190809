import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {Button, DatePicker, Form, Input, NumberKeyboard, Selector, TextArea, Toast, VirtualInput} from 'antd-mobile';
import dayjs from 'dayjs';
import {reqAddCustomerBill, reqGetCustomerBillInfo, reqGetCustomers, reqUpdateCustomerBill} from '../../api/customer';

const BillEdit = (props) => {
    const navigate = useNavigate();

    const [form1] = Form.useForm();

    const {mode} = props;
    const {id} = useParams();
    const [dateVisible, setDateVisible] = useState(false);
    useEffect(() => {
        if (id && mode === 'edit') {
            fillForm();
        } else {
            initAdd();
        }
    }, []);

    const [amountTime, setAmountTime] = useState(dayjs());

    const initAdd = async () => {
        const res = await reqGetCustomers({
            customerIds: id
        })

        if (res.code !== 200 || res.data.length === 0) return;

        form1.resetFields();
        setAmountTime( dayjs());
        form1.setFieldValue('billType', [1]);
        form1.setFieldValue('customerId', id);
        form1.setFieldValue('customerName', res.data[0].customerName);
    }

    const fillForm = async () => {
        const res = await reqGetCustomerBillInfo(id);
        if (res.code !== 200) return;
        setAmountTime(dayjs(res.data.amountTime));
        form1.setFieldValue('billType', [res.data.billType]);
        form1.setFieldValue('customerId', res.data.id);
        form1.setFieldValue('amount', res.data.amount + '');
        form1.setFieldValue('customerName', res.data.customerName);
    }

    const handleOk = async () => {
        const data = form1.getFieldsValue();
        let res;
        data['billType'] = data['billType'][0];
        data['amountTime'] = dayjs(amountTime).format();
        if (mode === 'add') {
            res = await reqAddCustomerBill(data);
        } else {
            data['id'] = id;
            res = await reqUpdateCustomerBill(data);
        }
        if (res.code !== 200) return;
        Toast.show({
            icon: 'success',
            content: res.msg
        });
        navigate(-1);
    }
    const NumberKeyboardWithContainer = (
        props,
    ) => {
        return <NumberKeyboard customKey={['.']} confirmText={'确定'} {...props} getContainer={props.container} />;
    };
    return (
        <div>
            <Form form={form1} footer={
                <Button onClick={handleOk} block color='primary' size='large'>
                    提交
                </Button>
            } layout='horizontal' >
                <Form.Item name={'customerId'} hidden>
                </Form.Item>
                <Form.Item  name={'customerName'} label={'流水客户'}>
                    <Input onClick={() => {
                        Toast.show({content: '不可编辑'})
                    }} />
                </Form.Item>
                <Form.Item label={'流水时间'}>
                    <Button onClick={() => {
                        setDateVisible(true);
                    }}>
                        {amountTime.format('YYYY-MM-DD')}
                    </Button>
                    <DatePicker
                        visible={dateVisible}
                        onConfirm={(value) => {
                            setAmountTime(dayjs(value))
                        }}
                        onClose={() => {
                            setDateVisible(false)
                        }}
                   />
                </Form.Item>
                <Form.Item required name={'amount'} label={'金额'}>
                    <VirtualInput
                        placeholder={'请输入金额'}
                        onChange={(value) => {
                            try {
                                if (value.endsWith('-')) {
                                    value = value.substring(0, value.length - 1);
                                    value = -(value - 0) + '';
                                }
                                if (!value.includes('.')) {
                                    value = (value - 0).toString();
                                }
                                if (value.endsWith('.') && value.substring(0, value.length - 1).toString().includes('.') === true) {
                                    value = value.substring(0, value.length - 1);
                                }
                                if (value.startsWith('.')) {
                                    value = '0.';
                                }
                                form1.setFieldValue('amount', value);
                            } catch {

                            }
                        }}
                        keyboard={<NumberKeyboardWithContainer customKey={['.', '-']} />}
                    />
                </Form.Item>
                <Form.Item name={'billType'} label={'流水类型'}>
                    <Selector
                        columns={1}
                        options={[
                            {
                                label: '欠',
                                value: 1,
                            },
                            {
                                label: '付',
                                value: 2,
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item name={'remark'} label={'备注'}>
                    <Input placeholder={'请填写备注'} />
                </Form.Item>
            </Form>
        </div>
    );
};

export default BillEdit;
