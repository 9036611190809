import React, {useEffect, useRef } from 'react';
import './login.css';
import {Toast} from 'antd-mobile';
import {reqLogin} from '../../api/login';


const Login = ({ setLoggedIn }) => {

    // 使用 useRef 创建表单元素的引用
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    useEffect(() => {
    }, []);
    const handleLogin = async (e) => {
        e.preventDefault(); // 阻止默认的表单提交行为
        // 通过引用获取表单元素的值
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        const data = { account: username, password};
        const res = await reqLogin(data);
        if (res.code !== 200) {
            Toast.show({
                content: res.msg,
                icon: 'fail',
            });
            return;
        }
        // 设置Token
        localStorage.setItem('jwt', res.data.access_token);
        Toast.show({
            content: `你好,${res.user.userName}`,
            icon: 'success',
        });
        setLoggedIn(true);
    }


    return (
        <div className={'login'}>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center mb-5">
                            <h2 className="heading-section">你 好</h2>
                        </div>
                    </div>
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5">
                                <div className="wrap">
                                    <div className="login-wrap p-4 p-md-5">
                                        <div className="d-flex">
                                            <div className="w-100">
                                                <h3 className="mb-4">登 录</h3>
                                            </div>
                                            <div className="w-100">
                                                <p className="social-media d-flex justify-content-end">
                                                    <a href="#" className="social-icon d-flex align-items-center justify-content-center"><span className="fa fa-facebook"></span></a>
                                                    <a href="#" className="social-icon d-flex align-items-center justify-content-center"><span className="fa fa-twitter"></span></a>
                                                </p>
                                            </div>
                                        </div>
                                        <form onSubmit={handleLogin} className="signin-form">
                                                <div className="form-group mt-3">
                                                    <input ref={usernameRef} type="text" className="form-control" required />
                                                    <label className="form-control-placeholder" htmlFor="username">用户名</label>
                                                </div>
                                                <div className="form-group">
                                                    <input ref={passwordRef} id="password-field" type="password" className="form-control" required />
                                                    <label className="form-control-placeholder" htmlFor="password">密码</label>
                                                    <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                                </div>

                                            <div className="form-group">
                                                <button  className="form-control btn btn-primary rounded submit px-3">Sign In</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
		</div>
	</section>
        </div>
    );
};

export default Login;
