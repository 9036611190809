import api from './api';

// 获取客户信息
export const reqGetCustomers = (query = {}) =>
    api.get('/customers', {
        params: query
    });

export const reqGetCustomersBill = (query) =>
    api.post('/customers/bills', query);

export const reqGetCustomerRelation = (id) =>
    api.get(`/customer/${id}/relation`);

export const reqAddCustomerRelation = (data) =>
    api.put('/customer/relation', data);

export const reqDeleteCustomers = (id) =>
    api.delete('/customer/' + id);

export const reqAddCustomer = (data) =>
    api.post('/customer', data);

export const reqUpdateCustomer = (data) =>
    api.put('/customer', data);

export const reqGetCustomerBillInfo = (id) =>
    api.get('customer/bill/' + id);
export const reqDeleteCustomerBillInfo = (id) =>
    api.delete('customer/bill/' + id);
export const reqAddCustomerBill = (data) =>
    api.post('customer/bill', data);

export const reqUpdateCustomerBill = (data) =>
    api.put('customer/bill', data);

export const reqGetCustomerBill = (id, params = {}) =>
    api.get('/customer/' + id +'/bills', {
        params
    })

export const reqClearCustomerBill = (id) =>
    api.post('/customer/'+ id +'/bill/clear');

export const reqGetCustomerGuid = (id) =>
    api.get('/customer/' + id + '/guid');

export const reqUpdateCustomerGuid = (id) =>
    api.put('/customer/' + id + '/guid');

export const reqvalidateCustomerGuid = (guid) =>
    api.get('/customer/guid/' + guid);

export const reqGetBooks = (query = {}) =>
    api.get('/books', {
        params: query
    })
